import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

export const uploadFileToSupabase = createAsyncThunk(
  'upload/uploadFileToSupabase',
  async (payload, thunkAPI) => {
    
    try {
      // 1. Upload the questionPaper to Supabase storage
      const { data: questionPaperUpload, error: questionPaperError } = await supabase
        .storage
        .from('past_papers')
        .upload(`questions/${payload.questionPaper.name}`, payload.questionPaper, {upsert: true});

      if (questionPaperError) {
        console.log(questionPaperError)
        throw Error('Error uploading question paper');
      }

      // Retrieve the public URL for the question paper
      const rawQuestionurl = supabase.storage.from('past_papers').getPublicUrl(`questions/${payload.questionPaper.name}`);
      const questionPaperUrl = rawQuestionurl.data.publicUrl
      // extract the form data from the payload 
      const formData = payload.fullForrmData
      const {
        title,
        year,
        month,
        season,
        has_answers,
        is_scan,
        number_of_questions,
        examBoard,
        fileName
      } = formData;

      // 2. Upsert the data to the past_papers table in Supabase
      const { data: upsertedData, error: upsertError } = await supabase
        .from('past_papers')
        .upsert([
          {
            updated_at: new Date().toISOString(),
            file_url: questionPaperUrl,
            title,
            year,
            month,
            season,
            has_answers,
            is_scan,
            number_of_questions,
            exam_board: examBoard,
            file_name: fileName,
          },
        ], {onConflict: 'file_url'}).select('id')

      if (upsertError) {
        console.log(upsertError )
        throw Error('Error upserting data to table');
      }

      // If an answerPaper is provided, upload it and store the URL and paper_id in a separate table
      if (payload.answerPaper) {
        // 3. Upload the answerPaper to Supabase storage
        const { data: answerPaperUpload, error: answerPaperError } = await supabase
          .storage
          .from('past_papers')
          .upload(`answers/${payload.answerPaper.name}`, payload.answerPaper, {upsert: true});

        if (answerPaperError) {
          console.log(answerPaperError)
          throw Error('Error uploading answer paper');
        }
        console.log("upserted data", upsertedData, questionPaperUrl)
        // Retrieve the public URL for the answer paper
       const rawUrl = supabase.storage.from('past_papers').getPublicUrl(`answers/${payload.answerPaper.name}`);
       const answerPaperUrl = rawUrl.data.publicUrl   
        // 4. Upsert the data to the past_paper_answers table in Supabase
        const { data: answerUpsertData, error: answerUpsertError } = await supabase
          .from('past_paper_answers')
          .upsert([
            {
              file_url: answerPaperUrl,
              paper_id: upsertedData[0].id,  // Assuming the ID of the new/updated paper is returned in the response
            },
          ],{onConflict: 'paper_id'});

        if (answerUpsertError) {
          console.log(answerUpsertError )
          throw Error('Error upserting data to answer table');
        }
      }

      return upsertedData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);



  export const checkSimilarTitles = createAsyncThunk(
    'papers/checkSimilarTitles',
    async (title, { rejectWithValue }) => {
     
      try {
        const { data, error } = await supabase
          .from('past_papers')
          .select('*')
          .ilike('file_name', title)
          .limit(1);
  
        if (error) {
          throw error;
        }
        console.log("SIM CHECK", data)
        // Return the entire row if a similar title is found, otherwise null
        return data.length > 0 ? data[0] : null;  
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

const initialState = {
  localFiles: [], // To store files locally before uploading to Supabase
  uploadedFiles: [], // To store metadata of files that have been successfully uploaded to Supabase
  selectedFileIndex: null,
  warnings: [],
  uploading: false, // To manage the loading state while a file is being uploaded to Supabase
  error: null,
  similarPaper: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    addLocalFiles(state, action) {
        // Storing only serializable file information
        const serializableFiles = action.payload.map(file => ({
          name: file.name,
          type: file.type,
          size: file.size,
          // ... any other serializable properties ...
        }));
        state.localFiles.push(...serializableFiles);
        
        if (state.selectedFileIndex === null && serializableFiles.length > 0) {
          state.selectedFileIndex = 0;
        }
      },
    removeLocalFile(state, action) {
      state.localFiles = state.localFiles.filter((_, index) => index !== action.payload); // Remove file from localFiles array by index
    },
    setUploading(state, action) {
      state.uploading = action.payload; // Set uploading status
    },
    addUploadedFile(state, action) {
      state.uploadedFiles.push(action.payload); // Add metadata of successfully uploaded file to uploadedFiles array
    },
    addWarning(state, action) {
      state.warnings.push(action.payload); // Add warning message to warnings array
    },
    clearWarnings(state) {
      state.warnings = []; // Clear all warning messages
    },
    nextFile(state) {
      if (state.selectedFileIndex < state.localFiles.length - 1) {
        state.selectedFileIndex += 1;
      }
    },
    previousFile(state) {
      if (state.selectedFileIndex > 0) {
        state.selectedFileIndex -= 1;
      }
    },
    selectFile(state, action) {
      state.selectedFileIndex = action.payload;
    },
    clearSimilarPaper(state) {
      state.similarPaper = null;
    },
    // ... other reducers ...
  },
    extraReducers: {
        [uploadFileToSupabase.pending]: (state, action) => {
        state.uploading = true;
        },
        [uploadFileToSupabase.fulfilled]: (state, action) => {
        state.uploading = false;
        state.uploadedFiles.push(action.payload);
        },
        [uploadFileToSupabase.rejected]: (state, action) => {
        state.uploading = false;
        state.error = action.payload;
        state.warnings.push(action.payload);
        },
        [checkSimilarTitles.pending]: (state, action) => {
        state.uploading = false
        },
        [checkSimilarTitles.fulfilled]: (state, action) => {
        state.uploading = false;
        state.similarPaper = action.payload;
        },
        [checkSimilarTitles.rejected]: (state, action) => {
        state.uploading = false;
        state.error = action.payload;
        state.warnings.push(action.payload);
        }
    },
});

export const {
  addLocalFiles,
  removeLocalFile,
  setUploading,
  addUploadedFile,
  addWarning,
  clearWarnings,
    nextFile,
    previousFile,
    selectFile,
    clearSimilarPaper,
  // ... other action creators ...
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

// Fetch all iterations
export const fetchAllIterations = createAsyncThunk(
  'iterations/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      const { data, error } = await supabase.from('job_iterations').select('*').order('created_at', { ascending: false });
      if (error) throw error;
      console.log(data, error)
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Create a new iteration
export const createNewIteration = createAsyncThunk(
  'iterations/createNew',
  async (title, { rejectWithValue }) => {
    try {
      const { data, error } = await supabase.from('job_iterations').insert([{ title }]);
      if (error) throw error;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const iterationSlice = createSlice({
  name: 'iterations',
  initialState: {
    list: [],
    currentIteration: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllIterations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllIterations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
        state.currentIteration = action.payload[0];  // Assuming the list is ordered by date descending
      })
      .addCase(fetchAllIterations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createNewIteration.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createNewIteration.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list.unshift(action.payload[0]);  // Add the new iteration to the front
        state.currentIteration = action.payload[0];
      })
      .addCase(createNewIteration.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default iterationSlice.reducer;

export default function getPrompt(questionNumbers) {
    const prompt =  (
        `Extract the text in the images into structured JSON objects, each representing an academic question. The images can either contain multiple-choice questions (MCQs) and extended multiple-choice questions (EMCQs), or calculation-based questions. Ensure that the JSON objects strictly adhere to the following rules:\n` +
        `- Enclose all property names and string values in double quotes.\n` +
        `- Separate multiple properties within an object with commas.\n` +
        `- Use a comma to separate different objects within the array.\n` +
        `- Start and end the response with square brackets to indicate an array.\n` +
        `- No text or any characters before or after the JSON output, only the JSON output.\n` +
        `- The question in the image must match the value you enter in qnumber.\n` +
        `- Only ever one correct option or answer.\n\n` +
        `The structure for each JSON object should be as follows:\n\n` +
        `[\n` +
        `    {\n` +
        `        "stem": "Provide context from the image",\n` +
        `        "main_question": "Transcribe the main question (usually in bold)",\n` +
        `        "options": {"A": "", "B": "", "C": "", "D": "", "E": "", "F": "", "G": "", "H": ""}, // Fill based on image content\n` +
        `        "hint": "Add if any hints are provided in the image",\n` +
        `        "explanation": "Create an explanation for the answer, or use working out for calculation questions",\n` +
        `        "answer": "Indicate the correct answer",\n` +
        `        "tags": ["Add relevant tags around the questions subject matter"],\n` +
        `        "subcategory_id": null,\n` +
        `        "additional_categories": [],\n` +
        `        "type": "Specify the question type - MCQ/EMCQ/Calculation - if there are 5 options value is "MCQ" and if there more than 5 value is "EMCQ" and if there are no options then its a Calculation",\n` +
        `        "difficulty": "Estimate difficulty for university students and give an integer 1 being the easiest and 5 being hardest",\n` +
        `        "units": "Include for calculation questions",\n` +
        `        "qnumber": "Assign question number"\n` +
        `    }\n` +
        `]\n\n` +
        `Rules you cannot break in the response:\n` +
        `- Do not include any text before or after the JSON objects.\n` +
        `- Do not include any additional properties in the JSON objects.\n` +
        `- You must open and close the object properly.\n` +
        `- Double check output will evaluate to valid JSON.\n` +
        `Note: It is crucial that the JSON objects are complete and align with the structure provided. There should be no additional text before or after the JSON objects. There can only be one correct answer!\n\n` +
        `Extract only the following question numbers from the images: questions ${questionNumbers.join(', ')}.`
    );
    // const cleanPrompt = prompt
    // .replace(/\\n/g, ' ') // Replace newline escape characters with spaces
    // .replace(/\\"/g, '"'); // Remove escape characters before quotes
    
    return prompt
    
    }

    export const systemPrompt = (
        `Extract the text in the images into structured JSON objects, each representing an academic question. The images can either contain multiple-choice questions (MCQs) and extended multiple-choice questions (EMCQs), or calculation-based questions. Ensure that the JSON objects strictly adhere to the following rules:\n` +
        `- Enclose all property names and string values in double quotes.\n` +
        `- Separate multiple properties within an object with commas.\n` +
        `- Use a comma to separate different objects within the array.\n` +
        `- Start and end the response with square brackets to indicate an array.\n` +
        `- No text or any characters before or after the JSON output, only the JSON output.\n` +
        `- The question in the image must match the value you enter in qnumber.\n` +
        `- Only ever one correct option or answer.\n\n` +
        `The structure for each JSON object should be as follows:\n\n` +
        `[\n` +
        `    {\n` +
        `        "stem": "Provide context from the image",\n` +
        `        "main_question": "Transcribe the main question (usually in bold)",\n` +
        `        "options": {"A": "", "B": "", "C": "", "D": "", "E": "", "F": "", "G": "", "H": ""}, // Fill based on image content\n` +
        `        "hint": "Add if any hints are provided in the image",\n` +
        `        "explanation": "Create an explanation for the answer, or use working out for calculation questions",\n` +
        `        "answer": "Indicate the correct answer",\n` +
        `        "tags": ["Add relevant tags around the questions subject matter"],\n` +
        `        "subcategory_id": null,\n` +
        `        "additional_categories": [],\n` +
        `        "type": "Specify the question type - MCQ/EMCQ/Calculation - if there are 5 options value is "MCQ" and if there more than 5 value is "EMCQ" and if there are no options then its a Calculation",\n` +
        `        "difficulty": "difficulty": "Estimate difficulty for university students and give an integer 1 being the easiest and 5 being hardest",\n` +
        `        "units": "Include for calculation questions",\n` +
        `        "qnumber": "Assign question number"\n` +
        `    }\n` +
        `]\n\n` +
        `Rules you cannot break in the response:\n` +
        `- Do not include any text before or after the JSON objects.\n` +
        `- Do not include any additional properties in the JSON objects.\n` +
        `- You must open and close the object properly.\n` +
        `- Double check output will evaluate to valid JSON.\n` +
        `Note: It is crucial that the JSON objects are complete and align with the structure provided. There should be no additional text before or after the JSON objects. There can only be one correct answer!\n\n` 
    )
    

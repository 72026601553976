import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createClient } from '@supabase/supabase-js';
import store from '../state/rootReducer';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

const supabase = createClient(supabaseUrl, supabaseAnonKey);

function withProviders(WrappedComponent) {
    return function WithProviders(props) {
        return (
            <Router>
                <Provider store={store}>
                    <WrappedComponent {...props} supabase={supabase} />
                </Provider>
            </Router>
        );
    };
}

export default withProviders;
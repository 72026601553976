import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LinearProgress, Typography, Card, CardActionArea, CardContent } from '@mui/material';

import { setSelectedPaper } from '../../../../state/processQuestions';


function Drawer({ currentPage, handlePageChange, perPage, total, questionPapers }) {
  const dispatch = useDispatch();


  const totalPages = Math.ceil(total / perPage);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const getCompletionPercentage = (paper) => {
    const { number_of_questions, questions } = paper;
    const completedCount = questions[0]?.count ?? 0; // Handle null or undefined
    return (completedCount / number_of_questions) * 100;
  };

const handlePaperClick = (paper) => {
  console.log({paper})
  dispatch(setSelectedPaper(paper));
  toggleDrawer();
}

  return (
    <div className="flex flex-col h-screen">
      <div className="bg-blue-500 w-full text-center p-4">
        <h2 className="text-white">Question Papers</h2>
      </div>

      <div className="flex flex-col overflow-y-auto p-4 space-y-4 ">
        {questionPapers.map((paper) => (
          <div
          onClick={
                () => handlePaperClick(paper)
              }
            key={paper.id}
            className="bg-white rounded-md shadow-md p-4 hover:bg-gray-200 hover:shadow-lg cursor-pointer"

          >
          {/*  <CardActionArea onClick={
          //     () => handlePaperClick(paper)
          //   }>
          //     <CardContent style={{height:100}}> 
          */}
                <Typography variant="h6">{paper.title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {getCompletionPercentage(paper)}% Completed
                </Typography>
                {/* <LinearProgress 
                  variant="determinate" 
                  value={getCompletionPercentage(paper)} 
                  sx={{ marginTop: 1 }}
                />
              </CardContent>
            </CardActionArea>*/}
          </div> 
          
        ))}

        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <Button
              key={page}
              variant={currentPage === page ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Drawer;

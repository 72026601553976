import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import AuthLayout from '../layouts/AuthLayout';

function withLayout(options) {
    return function(WrappedComponent) {
        return function(props) {
            const { layout } = options;
            
            if (layout === 'none') {
                return <WrappedComponent {...props} />;
            }

            if (layout === 'auth') {
                return (
                    <AuthLayout>
                        <WrappedComponent {...props} />
                    </AuthLayout>
                );
            }

            return (
                <DefaultLayout>
                    <WrappedComponent {...props} />
                </DefaultLayout>
            );
        };
    };
}


export default withLayout;

// import React from 'react';
// import withLayout from '../hocs/withLayout';

// function HomePage() {
//     return (
//         <div>
//             <h1>Home Page</h1>
//             <p>Welcome to my website!</p>
//         </div>
//     );
// }

// export default withLayout(HomePage, { layout: 'default' });

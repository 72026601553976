import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { Button, Switch, FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle, } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import { addLocalFiles, addWarning, nextFile, previousFile, clearWarnings, clearSimilarPaper } from '../../../../state/uploadQuestions/dashBoardSlice';
import { checkSimilarTitles } from '../../../../state/uploadQuestions/dashBoardSlice';

function Dashboard({ onToggleDrawer, fileRef, questionPaper, answerPaper, isQuestionPreview, setQuestionPaper, setAnswerPaper, setPreview, setIsQuestionPreview, validationMessages, reset, preview }) {
  const dispatch = useDispatch();
  const warnings = useSelector((state) => state.UploadQDashboard.warnings);
  const uploadedFiles = useSelector((state) => state.UploadQDashboard.localFiles); 
  const selectedFileIndex = useSelector((state) => state.UploadQDashboard.selectedFileIndex);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [similarPaper, setSimilarPaper] = useState(null);
  const similarExists = useSelector((state) => state.UploadQDashboard.similarPaper);
 

  const checkForSimilarTitle = useCallback(async (title) => {
      const similarTitle = await dispatch(checkSimilarTitles(title));
  }, [dispatch]);

  React.useEffect(() => {
    if (similarExists) {
      console.log({similarExists})
      setIsDialogOpen(true);
    }
  }, [similarExists]);


  const uploadQuestionPaper = (e) => {
    setQuestionPaper(e.target.files[0]);
    if (isQuestionPreview) setPreview(URL.createObjectURL(e.target.files[0]));
    checkForSimilarTitle(e.target.files[0].name); // Pass the file name to check
  };


  const uploadAnswerPaper = (e) => {
    setAnswerPaper(e.target.files[0]);
    if (!isQuestionPreview) setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const togglePreview = () => {
    setIsQuestionPreview(!isQuestionPreview);
    setPreview(isQuestionPreview 
      ? (answerPaper ? URL.createObjectURL(answerPaper) : null)
      : URL.createObjectURL(questionPaper)
    );
  };

  const uploadFiles = () => {
    const formData = new FormData();
    formData.append('questionPaper', questionPaper);
    formData.append('answerPaper', answerPaper);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSimilarPaper(null);
    dispatch(clearSimilarPaper());
    reset();
  };

  const handleContinueUpload = () => {
   setIsDialogOpen(false);
   dispatch(clearSimilarPaper());
  };

  return (
    <div className="p-4 bg-gray-200">
      <div>
  {validationMessages.map((message, index) => (
    <Alert key={index} severity="error">{message}</Alert>
  ))}
</div> 
      <div className="flex items-center justify-between mb-4">
        <IconButton onClick={onToggleDrawer} edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <div>
          <input 
            type="file"
            onChange={uploadQuestionPaper}
            style={{ display: 'none' }}
            id="question-upload"
          />
          <label htmlFor="question-upload">
            <Button variant="contained" component="span">Upload Question Paper</Button>
          </label>
          {questionPaper && <p>{questionPaper.name}</p>}

          <input 
            type="file"
            onChange={uploadAnswerPaper}
            style={{ display: 'none' }}
            id="answer-upload"
          />
          <label htmlFor="answer-upload">
            <Button variant="contained" component="span">Upload Answer Paper</Button>
          </label>
          {answerPaper && <p>{answerPaper.name}</p>}

          <FormControlLabel
            control={<Switch checked={isQuestionPreview} onChange={togglePreview} />}
            label="Preview Question Paper"
            disabled={!questionPaper}
          />
        </div>
      </div>
      <div>
        {warnings.length > 0 && warnings.map((warning, index) => (
          <Alert key={index} severity="warning">{warning}</Alert>
        ))}
      </div> 
      <div>
        <p>Uploaded Files: {uploadedFiles.length}</p>
    
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>Warning: Similar Title Found</DialogTitle>
        <DialogContent>
          {/* Show your similar and new paper details here for comparison and current paper in side by side view iframe */}
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <iframe 
      src={similarExists && similarExists.file_url} 
      title="Preview Similar Paper" 
      style={{ width: '45%', height: '500px' }}
    />
    <iframe 
      src={preview && preview} 
      title="Preview Current Paper" 
      style={{ width: '45%', height: '500px' }}
    />
  </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleContinueUpload} color="primary">
            Continue Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Dashboard;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

// Async Thunks
export const fetchAllPrompts = createAsyncThunk('prompts/fetchAllPrompts', async () => {
  const { data, error } = await supabase.from('prompts').select('*');
  if (error) throw error;
  return data;
});

export const addNewPrompt = createAsyncThunk('prompts/addNewPrompt', async (data) => {
  const { error } = await supabase.from('prompts').insert(data);
  if (error) throw error;
});

export const updatePrompt = createAsyncThunk('prompts/updatePrompt', async ({ id, data }) => {
  const { error } = await supabase.from('prompts').update(data).eq('id', id);
  if (error) {
    console.log(error)
    throw error
  }
});

export const fetchDefaultPrompts = createAsyncThunk('prompts/fetchDefaultPrompts', async () => {
  const { data, error } = await supabase.from('defaults').select('*');
  if (error) throw error;

  
  const rewriteDefault = data.find(item => item.title === 'rewrite');
  const questionGenDefault = data.find(item => item.title === 'question_gen');
  return {
    rewriteDefault: rewriteDefault?.rewritePromt,
    questionGenDefault: questionGenDefault?.questionGen
  };
});

export const updateDefaultPrompt = createAsyncThunk('prompts/updateDefaultPrompt', async ({ id, title, columnTitle }) => {
  const { error } = await supabase.from('defaults').update({ [columnTitle]: id }).eq('title', title);
  if (error){
    console.log(error)
    throw error;
  } 
});

// Slice
const promptsSlice = createSlice({
  name: 'prompts',
  initialState: {
    prompts: [],
    currentlySelectedPrompt: null,
    loading: false,
    error: null,
    rewriteDefault: "",
    questionGenDefault: "",
  },
  reducers: {
    selectPrompt: (state, action) => {
      state.currentlySelectedPrompt = action.payload;
    },
    selectDefaultRewritePrompt: (state, action) => {
        state.rewriteDefault = action.payload;
        },
    selectDefaultQuestionGenPrompt: (state, action) => {
        state.questionGenDefault = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllPrompts.fulfilled, (state, action) => {
        state.prompts = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllPrompts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllPrompts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Handle other Thunks similarly this prompt also adds and additional glag
      .addCase(fetchDefaultPrompts.fulfilled, (state, action) => {
        state.loading = false;
    
        const rewriteDefaultIndex = state.prompts.findIndex(item => item.id === action.payload.rewriteDefault);
        const questionGenDefaultIndex = state.prompts.findIndex(item => item.id === action.payload.questionGenDefault);
    
        if (rewriteDefaultIndex !== -1) {
            state.prompts[rewriteDefaultIndex] = {
                ...state.prompts[rewriteDefaultIndex],
                isDefault: true
            };
            state.rewriteDefault = state.prompts[rewriteDefaultIndex];
        }
    
        if (questionGenDefaultIndex !== -1) {
            state.prompts[questionGenDefaultIndex] = {
                ...state.prompts[questionGenDefaultIndex],
                isDefault: true
            };
            state.questionGenDefault = state.prompts[questionGenDefaultIndex];
        }
    })
    
      // Similarly handle .pending and .rejected for each thunk
  },
});

export const { selectPrompt, selectDefaultRewritePrompt, selectDefaultQuestionGenPrompt } = promptsSlice.actions;

export default promptsSlice.reducer;

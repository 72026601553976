function TopNavbar() {
    return (
      <div className="bg-blue-600 w-full fixed top-0 left-0 z-10 shadow-md">
        <div className="max-w-7xl mx-auto py-2 px-4">
          <h1 className="text-white text-xl font-bold">Learn Labs UK 🧪</h1>
        </div>
      </div>
    );
  }
  
  export default TopNavbar;
  
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

//thunk to fetch all questions for a given lesson
export const fetchLessonQuestions = createAsyncThunk(
    'questions/fetchLessonQuestions',
    async (lesson_id, { rejectWithValue }) => {
        try {
            const { data, error } = await supabase.from('questions').select('*').eq('lesson_id', lesson_id);
            if(error){
                console.log(error)
                throw error
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

//thunk to delete all questions for a given leson and insert new questions array for that lesson
export const updateLessonQuestions = createAsyncThunk(
    'questions/updateLessonQuestions',
    async (data, { rejectWithValue }) => {
        try {
            const { lesson_id, questions } = data;
            const { data: lesson, error } = await supabase.from('questions').delete().eq('lesson_id', lesson_id);
            if(error){
                console.log(error)
                throw error
            }
            console.log("DELETED")
        //enrich quetions array with new data
            questions.forEach((question) => {
                question.category_id = 1;
                question.type = "MCQ"
                question.lesson_id = lesson_id;
            });

            console.log("ENRICHED")

            const { data: newQuestions, error: newQuestionsError } = await supabase.from('questions').insert(questions);
            if(newQuestionsError){
                console.log(newQuestionsError)
                throw newQuestionsError
            }
            console.log("INSERTED")
            return newQuestions;
        } catch (error) {
            console.log(error)
            return rejectWithValue(error.message);
        }
    }
); 


const initialState = {
    questionsSlice: null,
    lessonQuestions: [],
    currentlySelectedQuestion: null,
    status: 'idle',
    error: null,
    loading: false,
};



const questionsSliceSlice = createSlice({
    name: 'questionsSlice',
    initialState,
    reducers: {
        setQuestionsSlice: (state, action) => {
            state.questionsSlice = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLessonQuestions.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(fetchLessonQuestions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.lessonQuestions = action.payload;
                state.loading = false;
            })
            .addCase(fetchLessonQuestions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(updateLessonQuestions.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(updateLessonQuestions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.lessonQuestions = action.payload;
                state.loading = false;
            })
            .addCase(updateLessonQuestions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                state.loading = false;
            })
    }
   
});

export const { setQuestionsSlice } = questionsSliceSlice.actions;

export default questionsSliceSlice.reducer;


import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';

function FilePreview({fileRef, preview}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); // set to whatever page you want to display

  const selectedFileIndex = useSelector((state) => state.UploadQDashboard.selectedFileIndex);


  

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const selectedFile = fileRef.current[selectedFileIndex];

  // Setup the workerSrc
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  return (
    <div className="flex-grow h-screen overflow-auto p-4">
          {preview ? (
          <iframe 
            src={preview} 
            title="Preview" 
            width="100%" 
            height="100%" 
          />
      ) : (
        <div>Please select a file to preview</div>
      )}
    </div>
  );
}

export default FilePreview;

import React from 'react'
import { Button, Dialog, DialogContent, DialogActions, CircularProgress } from '@mui/material'
import PromtDialog from './PromtDialog'
import { convertTextToSpeech } from '../../../../state/jobs/voiceOverSlice'
import { useDispatch, useSelector } from 'react-redux'
import { saveAudioToSupabase } from '../../../../state/jobs/voiceOverSlice'
import { updateJobLessons } from '../../../../state/jobs/lessonJob'
import { fetchAudioFromSupabase } from '../../../../state/jobs/voiceOverSlice'

function VoiceGen({sourceText, selectedJob, refetch, currentIteration}) {
    const dispatch = useDispatch();
    const [displayText, setDisplayText] = React.useState("Loading...")
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const [promtText, setPromtText] = React.useState("")
    const audioUrl = useSelector(state => state.voiceOverSlice.currentAudioUrl)
    const loading = useSelector(state => state.voiceOverSlice.loading)
    const lessonId = selectedJob.lesson_id
    const [blob, setBlob] = React.useState(null);

    
    const handleSourceTextChange = (e) => {
        setDisplayText(e.target.value);
    };

    const fetchAudioForLesson = async () => {
     const response = await dispatch(fetchAudioFromSupabase(lessonId))
      console.log(response.payload.voice_over)
    }

    React.useEffect(() => {
        fetchAudioForLesson();
    }, [lessonId, dispatch])

    const saveToDatabase = async () => {
     dispatch( saveAudioToSupabase(lessonId))
     await dispatch(updateJobLessons({ id: selectedJob?.id, ...selectedJob, ai_voiceOver: currentIteration.id }));
     await refetch();
     alert("Saved to database");
    }

    
const handleGenerateAudio = async () => {
    const response = await dispatch(convertTextToSpeech(displayText))
    console.log(response)
}

React.useEffect(() => {
    setDisplayText(sourceText?.ai_content)
}, [sourceText]);


  return (
    <div style={{ display: 'flex', height: '100vh', gap: '1rem' }}>
      
      <div style={{ flex: 1, flexDirection: 'column' }}>
        <div>
        <h2 style={{ textAlign: 'center' }}>Source</h2>
           
              <Button mode='outlined'>Save Changes</Button>
        </div>
       
        <textarea 
          style={{ width: '100%', height: '90%', resize: 'none', padding: '1rem',  border:'2px solid black' }} 
          defaultValue={displayText}
          value={displayText}
          onChange={handleSourceTextChange}
        />
      </div>
      
      <div style={{ flex: 1, flexDirection: 'column' }}>
        <h2 style={{ textAlign: 'center' }}>VoiceOver Generator</h2>
        <div>   
              <Button mode='outlined' onClick={() => {saveToDatabase()}}>Save To DataBase</Button>
              <Button mode='outlined' disabled onClick={() => setIsDialogOpen(true)}>Select Voice</Button>
              <Button mode='outlined' onClick={()=>{handleGenerateAudio()}}>Generate</Button>
        </div>
        <p>{audioUrl}</p>
        {
            loading && (
                <div style={{
                    width: '100%', height: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center', border:'2px solid black'
                }}>
                    <p>Refetching Data For the Text...</p>
                    <CircularProgress />
                </div>
            )
        }

        {audioUrl ? (
        <audio 
            controls 
            style={{ width: '100%', padding: '1rem', border: '2px solid black' }} 
            src={audioUrl}
        >
            Your browser does not support the audio element.
        </audio>
      
    ) : (
        <div style={{ width: '100%', height: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center', border:'2px solid black' }}>
            <p>No Audio Generated</p>
        </div>
    )}
      </div>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogContent>
            <PromtDialog />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => console.log("saved")}>Save</Button>
            <Button onClick={() => setIsDialogOpen(false)}>Close</Button>
        </DialogActions>
        </Dialog>

    </div>
  );
}

export default VoiceGen
// components/LessonSquare.js
import React from 'react';

function getColorForJob(completedJobs) {
  switch (completedJobs) {
    case 0:
      return 'bg-white';
    case 1:
      return 'bg-blue-300';
    case 2:
      return 'bg-orange-400';
    case 3:
      return 'bg-green-400';
    default:
      return 'bg-white';
  }
}

const LessonSquare = ({ job, currentIteration, onClick, currentJobId }) => {
  const completedJobs = 
    (job.ai_rewrite === currentIteration.id ? 1 : 0) + 
    (job.ai_questions === currentIteration.id ? 1 : 0) + 
    (job.ai_voiceOver === currentIteration.id ? 1 : 0);

    const isSelected = job.id === currentJobId;

if(isSelected) console.log("SELECTED")
  return (
    <div 
      className={` ${isSelected && 'border-2 border-black'} p-2 m-1 w-12 h-12 cursor-pointer  ${getColorForJob(completedJobs)}`} 
      onClick={onClick}
    >
      {completedJobs}
    </div>
  );
};

export default LessonSquare;

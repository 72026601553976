import React from 'react';
import withLayout from '../hooks/withLayout';

function NotFound() {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-6xl mb-4">🤷‍♂️</h1>
            <h2 className="text-2xl font-bold mb-2">Oops! Page not found.</h2>
            <p className="text-lg text-center">
                The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
            </p>
        </div>
    );
}

export default withLayout({ layout: 'default' })(NotFound);
import React from 'react'
import { Button, Dialog, DialogContent, DialogActions, CircularProgress } from '@mui/material'
import PromtDialog from './PromtDialog'
import { useDispatch, useSelector } from 'react-redux'
import { updateDefaultPrompt, updatePrompt } from '../../../../state/jobs/prompts';
import { fetchGptModel } from '../../../../state/jobs/openAiSlice';
import { updateCurrentLesson } from '../../../../state/jobs/lessonSlice';
import { updateJobLessons } from '../../../../state/jobs/lessonJob';
import { updateLessonQuestions, fetchLessonQuestions } from '../../../../state/jobs/questionsSlice';


function QuestionGen({ sourceText, aiText, prompts, selectedPrompt, defaultP, refetch, selectedJob, currentIteration, model}) {

    const dispatch = useDispatch();
    const filteredPrompts = prompts?.filter((prompt) => prompt.prompt_for === "question_gen")
    const [displayText, setDisplayText] = React.useState(sourceText?.ai_content || "pending")
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const [promtText, setPromtText] = React.useState("")
    const [selectedPromptDropDown, setSelectedPromptDropDown] = React.useState(defaultP ? defaultP : "");
    const [aITextAreaValue, setAiTextAreaValue] = React.useState("");
    const chatGptResponse = useSelector(state => state.openAi.gptResponseQuestions)
    const gptLoading = useSelector(state => state.openAi.loading)
    const isDefault = selectedPromptDropDown?.isDefault
    const lessonQuestions = useSelector(state => state.questionsSlice.lessonQuestions)



    //if lesson questions array exists parse the whole array and output it to the ai text area
    React.useEffect(() => {
      if (lessonQuestions?.length > 0) {
          const keysToKeep = ['main_question', 'options', 'answer', 'hint', 'explanation', 'tags']; // Add the keys you want to keep
  
          const filteredQuestions = lessonQuestions.map(question => {
              return keysToKeep.reduce((acc, key) => {
                  acc[key] = question[key];
                  return acc;
              }, {});
          });
  
          const stringifiedQuestions = JSON.stringify(filteredQuestions, null, 2);
          setAiTextAreaValue(stringifiedQuestions);
      }
  }, [lessonQuestions]);
  
  
  

    React.useEffect(() => {
      dispatch(fetchLessonQuestions(selectedJob?.lesson_id))
    }, [selectedJob, dispatch])

    React.useEffect(() => {
      if(
        selectedJob?.QuestionGen === currentIteration.id 
      ){
        setAiTextAreaValue(sourceText.ai_content);
      }
     else {
        setAiTextAreaValue("No questions Have Been Generated Yet");
      }
  }, [sourceText]);


  
React.useEffect(() => {
  setAiTextAreaValue(gptLoading ? `Fetching value for the following prompt..." \n\n +${selectedPromptDropDown?.prompt_text}` : chatGptResponse);
}, [gptLoading, selectedPromptDropDown, chatGptResponse]);
    
    const handleSourceTextChange = (e) => {
        setDisplayText(e.target.value);
    };
    
    const handleMakeDefault = async () => {
        const data = { id:selectedPromptDropDown.id, title:'rewrite', columnTitle:'rewritePromt' }
        await dispatch(updateDefaultPrompt(data))
        await refetch()
    }
    
    const handleSaveAndUse = async () => {
        // make a copy of selectedPromptDropDown and delete isDefault property
        const promptToUpdate = { ...selectedPromptDropDown };
        delete promptToUpdate.isDefault;
        
        console.log(promptToUpdate);
        await dispatch(updatePrompt({ id: selectedPromptDropDown.id, data: promptToUpdate }));
        await refetch();
        dispatch(selectedPrompt(selectedPromptDropDown));
    }

    const handleGenerateAIResponse = async () => {
      const prompt = selectedPromptDropDown?.prompt_text
      const text = sourceText?.html_content
      const fullPrompt = `${prompt}.   ${text}`
      console.log({fullPrompt})
      const response = await dispatch(fetchGptModel({fullPrompt, model, type:"question"}))
      console.log(response)
  }

    
  const handleSaveToDataBase = async () => {
    if (!aITextAreaValue) {
        alert("Please generate AI response first");
        return;
    }

    try {
        const startIndex = aITextAreaValue.indexOf('[');
        const endIndex = aITextAreaValue.lastIndexOf(']');

        if (startIndex === -1 || endIndex === -1 || startIndex > endIndex) {
            console.error("Invalid JSON string: Couldn't find matching brackets");
            return;
        }

        const jsonArrayStr = aITextAreaValue.substring(startIndex, endIndex + 1);

        // Directly parse the JSON string as it's assumed to be valid
        const cleanGptResponse = JSON.parse(jsonArrayStr);
        console.log({ cleanGptResponse });

        // Proceed with dispatching actions with the cleaned response
        await dispatch(updateLessonQuestions({ lesson_id: selectedJob?.lesson_id, questions: cleanGptResponse }));
        await dispatch(updateJobLessons({ id: selectedJob?.id, ...selectedJob, ai_questions: currentIteration.id }));

        await refetch();
        alert("Saved to database");

    } catch (error) {
        console.error("Error processing the GPT response:", error);
        alert("Error processing the GPT response. Please check the console for more details.");
    }
}


    
  // Handling the change event of textarea
const handleAiTextAreaChange = (e) => {
  setAiTextAreaValue(e.target.value);
}

  return (
    <div style={{ display: 'flex', height: '100vh', gap: '1rem' }}>
      
      <div style={{ flex: 1, flexDirection: 'column' }}>
        <div>
        <h2 style={{ textAlign: 'center' }}>Source</h2>
           
              <Button mode='outlined'>Save Changes</Button>
        </div>
       
        <textarea 
          style={{ width: '100%', height: '90%', resize: 'none', padding: '1rem',  border:'2px solid black' }} 
          defaultValue={displayText}
          value={displayText}
          onChange={handleSourceTextChange}
        />
      </div>
      
      <div style={{ flex: 1, flexDirection: 'column' }}>
        <h2 style={{ textAlign: 'center' }}>AI Questions json</h2>
        <div>   
        <Button mode='outlined' onClick={() => handleSaveToDataBase()}>Save To DataBase</Button>
              <Button mode='outlined' onClick={()=>{setIsDialogOpen(true)}}>View Prompt</Button>
              <Button mode='outlined' onClick={() => handleGenerateAIResponse()}>Generate</Button>
        </div>

        <textarea
    style={{
      width: '100%',
      height: '100%',
      resize: 'none',
      padding: '1rem',
      border: '2px solid black',
      color: gptLoading ? '#999' : 'initial',
      fontStyle: gptLoading ? 'italic' : 'normal',
      opacity: gptLoading ? 0.5 : 1
    }}
    value={aITextAreaValue}
    onChange={handleAiTextAreaChange}
    disabled={gptLoading}
  />
  {gptLoading && (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 2
    }}>
      <CircularProgress />
    </div>
  )}
      </div>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogContent>
            <PromtDialog defaultP={defaultP} title="Question Gen" prompts={filteredPrompts} selectedPrompt={selectedPromptDropDown} 
            refetch={refetch}
            setSelectedPrompt={setSelectedPromptDropDown}
            />
        </DialogContent>
        <DialogActions>
        <Button onClick={() => handleSaveAndUse()}>Save</Button>
            <Button onClick={() => handleMakeDefault()} disabled={isDefault}>Make Default</Button>
            <Button onClick={() => setIsDialogOpen(false)}>Close</Button>
          
        </DialogActions>
        </Dialog>

    </div>
  );
}

export default QuestionGen
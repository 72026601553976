import { configureStore } from '@reduxjs/toolkit';
import user from './user';
import iterationsSlice from './jobs/iterationsSlice';
import lessonJob from './jobs/lessonJob';
import aIQuestions from './jobs/aIQuestions';
import aIRewrite from './jobs/aIRewrite';
import aIVoiceOver from './jobs/aIVoiceOver';
import lessonSlice from './jobs/lessonSlice';
import questionsSlice from './jobs/questionsSlice';
import voiceOverSlice from './jobs/voiceOverSlice';
import prompts from './jobs/prompts';
import openAiSlice from './jobs/openAiSlice';
import UploadQDashboard from './uploadQuestions/dashBoardSlice'
import openai from '../services/openai';
import Google from './services/Google';
import SnackBar from './system/SnackBar';
import processQuestions from './processQuestions';
import imagifySlice from './imagify/imagifySlice';

const store = configureStore({
    reducer: {
        user,
        iterations: iterationsSlice,
        lessonJob,
        aIQuestions,
        aIRewrite,
        aIVoiceOver,
        lessonSlice,
        questionsSlice,
        voiceOverSlice,
        prompts,
        openAi: openAiSlice,
        UploadQDashboard,
        openai,
        Google,
        SnackBar,
        processQuestions,
        imagifySlice,
    },
});

export default store;
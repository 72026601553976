import { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import MenuIcon from '@material-ui/icons/Menu';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedQuestion } from '../../../../state/processQuestions';
import { toggleFullScreen } from '../../../../state/system/SnackBar';

function Dashboard({ onToggleDrawer, }) {
  const dispatch = useDispatch();
  const selectedPaper = useSelector((state) => state.processQuestions.selectedPaper);
  const questions = useSelector((state) => state.processQuestions.questions);
  const selectedQuestion = useSelector((state) => state.processQuestions.selectedQuestion);
  const [isDashboardVisible, setDashboardVisible] = useState(true);
  const isFullScreen = useSelector((state) => state.SnackBar.isFullScreen);
 

  useEffect(() => {
    if (isFullScreen) {
      setDashboardVisible(false);
    }
  }, [isFullScreen]);


  const handleQuestionClick = (index) => {
    // Check if there's already a question with a matching qnumber.
    const existingQuestion = questions.find((q) => q.qnumber === index + 1);

    if (existingQuestion) {
      // If the question exists, select it.
      dispatch(setSelectedQuestion(existingQuestion));
    } else {
      // If it doesn't, create a new question with the clicked index + 1 as its qnumber.
      const newQuestion = {
        qnumber: index + 1,
        // ... other properties ...
      };

      // Add the new question and select it.
      dispatch(setSelectedQuestion(newQuestion));
    }
  };

  const toggleDashboard = () => {
    setDashboardVisible(!isDashboardVisible);
  };

  const hanldeFullScreen = () => {
   const nextIsFullScreen = !isFullScreen;
    dispatch(toggleFullScreen());
    if (nextIsFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <div className="overflow-x-auto p-4">
      <div className="flex items-center justify-between mb-4">
        <IconButton onClick={onToggleDrawer} edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <div>
          <IconButton onClick={toggleDashboard} edge="start" color="inherit" aria-label="toggle dashboard">
            {isDashboardVisible ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>

          <IconButton onClick={hanldeFullScreen} edge="start" color="inherit" aria-label="toggle screen">
            {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </div>
      </div>
      {isDashboardVisible && (
        <>
          Dashboard
          {selectedPaper && (
            <div className="flex flex-wrap space-x-2 space-y-2 p-4">
              {Array.from({ length: selectedPaper.number_of_questions }, (_, index) => {
                // These variables must be inside the map function to access the current index.
                const existingQuestion = questions.find((q) => q.qnumber === index + 1);

                // Use the qnumber for determining if the question is selected.
                const isSelected = (index + 1) === selectedQuestion?.qnumber;

                return (
                  <div
                    key={index}
                    onClick={() => handleQuestionClick(index)}
                    className={`w-8 h-8 border-2 flex items-center justify-center cursor-pointer text-white ${
                      isSelected
                        ? 'bg-blue-600' // Selected question
                        : existingQuestion
                        ? 'bg-green-500' // Processed but not selected question
                        : 'bg-gray-500' // Unprocessed question
                    }`}
                  >
                    {index + 1}
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard;
// NavigationItem.js
import { ListItem } from '@mui/material';
import { Link } from 'react-router-dom';

function NavigationItem({ label, path }) {
  return (
    <ListItem button component={Link} to={path}>
      {label}
    </ListItem>
  );
}

export default NavigationItem;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

const PER_PAGE = 100;

// Async thunk to populate job lessons
export const populateJobLessons = createAsyncThunk(
    'jobs/populateJobLessons',
    async (_, { rejectWithValue }) => {
        try {
            const { data, error } = await supabase.rpc('populate_job_lessons');
            if (error) throw error;
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Async thunk to fetch all job lessons
export const fetchAllJobLessons = createAsyncThunk(
    'jobs/fetchAllJobLessons',
    async (options = {}, { rejectWithValue }) => {
        try {
            const { page = 1, lesson_id } = options;
            const startAt = (page - 1) * PER_PAGE;


            const { data, error } = await supabase
                .rpc('fetch_ordered_job_lessons', { 
                    offset_val: startAt, 
                    limit_val: PER_PAGE, 
                    lesson_id_val: lesson_id 
                });
       
            if (error && error.code == "22P02" ){
                return rejectWithValue("Invalid lesson ID");
            } else if (error) {
                console.log("RUNNIN REJECTION 2")
                 throw error
            }
        
            return { 
                data: data.map(item => item.result),
                totalCount: data[0]?.total_count || 0,
                currentPage: page
            };

        } catch (error) {
        
            return rejectWithValue(error.message);
        }
    }
);

// Slice update job_lessons
export const updateJobLessons = createAsyncThunk(
    'jobs/updateJobLessons',
    async (data, { rejectWithValue }) => {
        try {
            const { id, ...fields } = data;
            delete fields.id;
            fields.updated_at = new Date();
         
            const { data: job, error } = await supabase.from('job_lessons').update(fields).eq('id', id).single();
            if(error){
                console.log(error)
                throw error
            }
            return job;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);




const lessonJobsSlice = createSlice({
    name: 'jobs',
    initialState: {
        jobs: [],
        status: 'idle',
        error: null,
        loading: false,
        currentlySelectedJob: null,
        previouslySelectedJob: null,
        nextSelectedJob: null,
        currentPage: 1,
        perPage: PER_PAGE,
        hasMore: true,
        totalPages: null
    },
    reducers: {
        selectJob: (state, action) => {
            const currentIndex = state.jobs.findIndex(job => job.id === action.payload.id);
            
            state.currentlySelectedJob = state.jobs[currentIndex];
            state.previouslySelectedJob = state.jobs[currentIndex - 1] || null;
            state.nextSelectedJob = state.jobs[currentIndex + 1] || null;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        clearErrors: (state) => {
            state.error = null;
        },
        nextSelectedJob: (state) => {
            const currentIndex = state.jobs.findIndex(job => job.id === state.currentlySelectedJob.id);
            state.currentlySelectedJob = state.jobs[currentIndex + 1];
            state.previouslySelectedJob = state.jobs[currentIndex] || null;
            state.nextSelectedJob = state.jobs[currentIndex + 2] || null;
        },
        previousSelectedJob: (state) => {
            const currentIndex = state.jobs.findIndex(job => job.id === state.currentlySelectedJob.id);
            state.currentlySelectedJob = state.jobs[currentIndex - 1];
            state.previouslySelectedJob = state.jobs[currentIndex - 2] || null;
            state.nextSelectedJob = state.jobs[currentIndex - 1] || null;
        },
    },
    extraReducers: {
        [populateJobLessons.pending]: (state) => {
            state.loading = true;
        },
        [populateJobLessons.fulfilled]: (state, action) => {
            console.log(action.payload)
        },
        [populateJobLessons.rejected]: (state, action) => {
            console.log(action.payload)
        },
        [fetchAllJobLessons.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllJobLessons.fulfilled]: (state, action) => {
            state.jobs = action.payload.data;
            state.currentPage = action.payload.currentPage;
            state.totalPages = Math.ceil(action.payload.totalCount / PER_PAGE);
            state.loading = false;
        },
        [fetchAllJobLessons.rejected]: (state, action) => {
            console.log("erro" , action.payload)
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { selectJob, setCurrentPage, clearErrors, nextSelectedJob, previousSelectedJob } = lessonJobsSlice.actions;

export default lessonJobsSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

const API_KEY = process.env.REACT_APP_ELEVEN_LABS_KEY


export const saveAudioToSupabase = createAsyncThunk(
    'voiceOverSlice/saveAudioToSupabase',
    async (lessonId, { getState, rejectWithValue }) => {
        const audioUrl = getState().voiceOverSlice.currentAudioUrl;
        try {
            // Convert URL to Blob
            const response = await fetch(audioUrl);
            if (!response.ok) throw new Error('Failed to fetch the audio URL');
            const blob = await response.blob();

            try {
                // Attempt to upload the audio blob to the 'lesson_voice_overs' bucket
                await supabase.storage
                    .from('lesson_voice_overs')
                    .upload(`${lessonId}`, blob); 
            } catch (uploadError) {
                console.log("REPLACING1" )
                
                if(uploadError.status_code === '409' || uploadError.error === 'Duplicate') {
                    console.log("REPLACING")
                    await supabase.storage
                    .from('lesson_voice_overs')
                    .update(`${lessonId}`, blob);
                } else {
                    // If error is other than conflict, throw it
                    console.log(uploadError)
                    throw uploadError;
                }
            }

            // Get the URL of the uploaded file
const { data } = supabase.storage.from('lesson_voice_overs').getPublicUrl(`${lessonId}`);
const fileURL = data?.publicUrl;
if (!fileURL) throw new Error('Failed to get the public URL of the uploaded file');


            // Update the 'l_lessons' table with the URL of the uploaded audio
            const { error: updateError } = await supabase
                .from('l_lessons')
                .update({ voice_over: fileURL })
                .eq('id', lessonId);

            if (updateError) {
                console.log(updateError)
                throw updateError;
            }

            return { fileURL };
        } catch (error) {
            console.error("Error uploading audio to Supabase:", error.message);
            return rejectWithValue(error.message);
        }
    }
);


// fetch given lessons audio url
export const fetchAudioFromSupabase = createAsyncThunk(
    'voiceOverSlice/fetchAudioFromSupabase',
    async (lessonId, { rejectWithValue }) => {
        try {
            const { data, error } = await supabase.from('l_lessons').select('voice_over').eq('id', lessonId).single();
            if(error){
                console.log(error)
                throw error;
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const convertTextToSpeech = createAsyncThunk(
    'voiceOverSlice/convertTextToSpeech',
    async (text, { getState, rejectWithValue }) => {
        const voice_id = getState().voiceOverSlice.selectedVoice.voice_id;

      try {
        const response = await fetch(`https://api.elevenlabs.io/v1/text-to-speech/${voice_id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'xi-api-key': API_KEY, // Replace with your API Key
          },
          body: JSON.stringify({
            text: text,
            model_id: 'eleven_monolingual_v1',
            voice_settings: {
              stability: 0,
              similarity_boost: 0,
              style: 0,
              use_speaker_boost: true,
            },
          }),
        });
  
        if (!response.ok) {
            console.log(response)
          const error = await response.json();
          return rejectWithValue(error);
        }
  
        const blob = await response.blob();
        const audioUrl = URL.createObjectURL(blob);
        return { audioUrl };
      } catch (error) {
        console.log(error)
        return rejectWithValue(error.message);
      }
    }
  );

  export const fetchVoices = createAsyncThunk(
    'voiceOverSlice/fetchVoices',
    async (_, { rejectWithValue }) => {
      try {
        const response = await fetch('https://api.elevenlabs.io/v1/voices', {
          method: 'GET',
          headers: {
            'xi-api-key': API_KEY, // Replace with your API Key
          },
        });

        console.log(response)
  
        if (!response.ok) {
          const error = await response.json();
          return rejectWithValue(error);
        }
  
        const data = await response.json();
        return { voices: data.voices };
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
  
  


const initialState = {
    voiceOverSlice: null,
    status: 'idle',
    error: null,
    loading: false,
    currentAudioUrl: null,
    voices: [],
    selectedVoice: null,
   
};



const voiceOverSlice = createSlice({
    name: 'voiceOverSlice',
    initialState,
    reducers: {
        setVoiceOverSlice: (state, action) => {
            state.voiceOverSlice = action.payload;
        },
        setSelectedVoice: (state, action) => {
   
            state.selectedVoice = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(convertTextToSpeech.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(convertTextToSpeech.fulfilled, (state, action) => {
            state.loading = false;
            state.currentAudioUrl = action.payload.audioUrl;
          
          })
          .addCase(convertTextToSpeech.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
          })
          .addCase(fetchVoices.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(fetchVoices.fulfilled, (state, action) => {
            state.loading = false;
            state.voices = action.payload.voices; // You might need to add 'voices' to your initialState
          })
          .addCase(fetchVoices.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
          })
            .addCase(fetchAudioFromSupabase.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(fetchAudioFromSupabase.fulfilled, (state, action) => {
              
                state.status = 'succeeded';
                state.loading = false;
                state.currentAudioUrl = action.payload.voice_over;
            })
            .addCase(fetchAudioFromSupabase.rejected, (state, action) => {
                state.status = 'failed';
                state.loading = false;
                state.error = action.error.message;
            })
      },
   
});

export const { setVoiceOverSlice, setSelectedVoice } = voiceOverSlice.actions;
export default voiceOverSlice.reducer;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

const initialState = {
    aIRewrite: null,
    status: 'idle',
    error: null,
    loading: false,
};

export const fetchAIRewrite = createAsyncThunk('aIRewrite/fetchAIRewrite', async () => {
    const { data, error } = await supabase.from('aIRewrite').select('*').single();
    if (error) {
        throw error;
    }
    return data;
});

const aIRewriteSlice = createSlice({
    name: 'aIRewrite',
    initialState,
    reducers: {
        setAIRewrite: (state, action) => {
            state.aIRewrite = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAIRewrite.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAIRewrite.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.aIRewrite = action.payload;
            })
            .addCase(fetchAIRewrite.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const { setAIRewrite } = aIRewriteSlice.actions;

export default aIRewriteSlice.reducer;
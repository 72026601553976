import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QuestionGen from './QuestionGen';
import Rewrite from './Rewrite';
import VoiceGen from './VoiceGen';
import { CircularProgress, Button } from '@mui/material';
import { UseSelector } from 'react-redux/es/hooks/useSelector';
import { selectPrompt, selectDefaultQuestionGenPrompt,  selectDefaultRewritePrompt } from '../../../../state/jobs/prompts';
import { fetchAllPrompts } from '../../../../state/jobs/prompts';
import { fetchDefaultPrompts } from '../../../../state/jobs/prompts';

function ContentArea({ selectedJob, lesson, currentIteration, model}) {
    const dispatch = useDispatch();
    const [activeComponent, setActiveComponent] = React.useState('rewrite');
    const prompts = useSelector(state => state.prompts.prompts)
    const currentlySelectedPrompt = useSelector(state => state.prompts.currentlySelectedPrompt)
    const defaultReWrite =  useSelector(state => state.prompts.rewriteDefault)
    const defaultQuestionGen =  useSelector(state => state.prompts.questionGenDefault)

    const components = ['rewrite', 'questionGen', 'voiceGen'];

 

    const fetchPromptsAndDefaults = async () => {
        await dispatch(fetchAllPrompts());
        await dispatch(fetchDefaultPrompts());
      };

    React.useEffect(() => {
        fetchPromptsAndDefaults();
      }, [dispatch]);

    const getActiveComponent = () => {
        switch (activeComponent) {
            case 'questionGen':
                return <QuestionGen sourceText={lesson} model={model} defaultP={defaultQuestionGen} prompts={prompts} selectedPrompt={selectDefaultQuestionGenPrompt} refetch={fetchPromptsAndDefaults}
                selectedJob={selectedJob} currentIteration={currentIteration}
                />;
            case 'rewrite':
                return <Rewrite sourceText={lesson} model={model} defaultP={defaultReWrite} prompts={prompts} selectedPrompt={selectDefaultRewritePrompt} refetch=
                {fetchPromptsAndDefaults}
                selectedJob={selectedJob} currentIteration={currentIteration}
                />;
            case 'voiceGen':
                return <VoiceGen sourceText={lesson} refetch={fetchPromptsAndDefaults} model={model} 
                selectedJob={selectedJob} currentIteration={currentIteration}
                />;
            default:
                return <Rewrite sourceText={lesson} model={model} defaultP={defaultReWrite} prompts={prompts} selectedPrompt={selectDefaultRewritePrompt} refetch={fetchPromptsAndDefaults}
                selectedJob={selectedJob} currentIteration={currentIteration}
                />;
        }
    }

    const handleNext = () => {
        const currentIndex = components.indexOf(activeComponent);
        if (currentIndex < components.length - 1) {
            setActiveComponent(components[currentIndex + 1]);
        }
    }

    const handlePrev = () => {
        const currentIndex = components.indexOf(activeComponent);
        if (currentIndex > 0) {
            setActiveComponent(components[currentIndex - 1]);
        }
    }
    const Timeline = () => {
        // Mapping the active component to the corresponding job status
        const jobStatusMap = {
            'rewrite': 'ai_rewrite',
            'questionGen': 'ai_questions',
            'voiceGen': 'ai_voiceOver'
        };
        
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {components.map((component) => {
                    const jobStatus = selectedJob[jobStatusMap[component]];
                    let backgroundColor = 'grey'; // default color
                    if (jobStatus === currentIteration.id) {
                        backgroundColor = 'green'; // job is complete
                    } else if (jobStatus > 0) {
                        backgroundColor = 'orange'; // job is not complete at all
                    }
    
                    let borderColor = component === activeComponent ? 'blue' : backgroundColor; // highlight the active component with a bright color border
                    
                    return (
                        <div
                            key={component}
                            style={{
                                padding: '10px',
                                borderRadius: '5px',
                                backgroundColor: backgroundColor,
                                border: `2px solid ${borderColor}`, // Set border color here
                            }}
                        >
                            {component}
                        </div>
                    )
                })}
            </div>
        );
    }

    if (!selectedJob) {
        return (
            <div>
                <h4>Please Select A Lesson from the Drawer</h4>
            </div>
        )
    }

    return (
        <div>
            <Timeline selectedJob={selectedJob} currentIteration={currentIteration}/>
            <Button onClick={handlePrev} disabled={activeComponent === 'rewrite'}>Previous</Button>
            <Button onClick={handleNext} disabled={activeComponent === 'voiceGen'}>Next</Button>
            {getActiveComponent()}
        </div>
    )
}

export default ContentArea;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectFile } from '../../../../state/uploadQuestions/dashBoardSlice'; // Adjust the import path
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

function SideDrawer() {
  const dispatch = useDispatch();
  const uploadedFiles = useSelector((state) => state.UploadQDashboard.localFiles);
  const selectedFileIndex = useSelector((state) => state.UploadQDashboard.selectedFileIndex);
  
  const handleFileClick = (index) => {
    dispatch(selectFile(index)); // Update the selectedFileIndex in Redux state
  };
  
  return (
    <div className="p-4">
      <div className="mb-4">
        <h2 className="text-xl font-semibold mb-2">Uploaded Files</h2>
        <List component="nav">
          {uploadedFiles.map((file, index) => (
            <ListItem 
              button 
              key={index} 
              onClick={() => handleFileClick(index)}
              selected={index === selectedFileIndex} // Highlight if this file is selected
            >
              <ListItemText primary={file.name} />
            </ListItem>
          ))}
        </List>
      </div>
      <Divider />
      <div className="mt-4">
        <h2 className="text-xl font-semibold mb-2">Database Files</h2>
        {/* Placeholder for Database Files. */}
      </div>
    </div>
  );
}

export default SideDrawer;

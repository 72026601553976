import React, {useState} from 'react'
import withLayout from '../../../hooks/withLayout'
import Header from './components/Header'
import JobDrawer from './components/JobDrawer'
import { fetchAllJobLessons } from '../../../state/jobs/lessonJob'
import { useDispatch, useSelector } from 'react-redux'
import ContentArea from './components/ContentArea'
import { fetchCurrentLesson } from '../../../state/jobs/lessonSlice'

function PsHome() {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const jobs = useSelector(state => state.lessonJob.jobs)
    const selectedJob = useSelector(state => state.lessonJob.currentlySelectedJob)
    const currentIteration = useSelector(state => state.iterations.currentIteration)
    const lesson = useSelector(state => state.lessonSlice.currentLesson)
    const gptModels =['gpt-4', 'gpt-4-32k', 'gpt-3.5-turbo', 'gpt-3.5-turbo-16k']
    const dispatch = useDispatch()
    const [selectedGptModel, setSelectedGptModel] = useState('gpt-4')

  

    React.useEffect(() => {
        dispatch(fetchAllJobLessons())
    }, [dispatch])

    React.useEffect(() => {
        if(selectedJob){
            dispatch(fetchCurrentLesson(selectedJob.lesson_id))
        }
    }, [selectedJob, dispatch])

    React.useEffect(() => {
        if(lesson){
            console.log("LESSON ID",lesson.id)
        }
    }, [lesson])

  return (
    <div>
        <Header onOpenDrawer={() => setIsDrawerOpen(true)} selectedJob={selectedJob} gptModels={gptModels} setModel={setSelectedGptModel} model={selectedGptModel}/>
        <JobDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}/>
        <ContentArea selectedJob={selectedJob} lesson={lesson} currentIteration={currentIteration}  model={selectedGptModel}/>
    </div>
  )
}

export default withLayout({ layout: 'default' })(PsHome)
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

export const fetchCurrentLesson = createAsyncThunk(
    'lessons/fetchCurrentLesson',
    async (lesson_id, { rejectWithValue }) => {
        try {
            const { data, error } = await supabase.from('l_lessons').select('*').eq('id', lesson_id).single();
            if(error){
                console.log(error)
                throw error
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateCurrentLesson = createAsyncThunk(
    'lessons/updateCurrentLesson',
    async (data, { rejectWithValue }) => {
        try {
            const { id, ...fields } = data;
            const { data: lesson, error } = await supabase.from('l_lessons').update(fields).eq('id', id).single();
            if(error){
                console.log(error)
                throw error
            }
            return lesson;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchPreviousAndNextLessons = createAsyncThunk(
    'lessons/fetchPreviousAndNextLessons',
    async (prev_id, next_id, { rejectWithValue }) => {
        try {
            if(!prev_id && !next_id){
                return { previousLesson: null, nextLesson: null };
            }
            if(!prev_id){
                const { data: nextLesson, error: nextError } = await supabase.from('l_lessons').select('*').eq('id', next_id).single();
                if(nextError){
                    console.log(nextError)
                    throw nextError
                }
                return { previousLesson: null, nextLesson };
            }
            if(!next_id){
                const { data: previousLesson, error: prevError } = await supabase.from('l_lessons').select('*').eq('id', prev_id).single();
                if(prevError){
                    console.log(prevError)
                    throw prevError
                }
                return { previousLesson, nextLesson: null };
            }
            const { data: previousLesson, error: prevError } = await supabase.from('l_lessons').select('*').eq('id', prev_id).single();
            const { data: nextLesson, error: nextError } = await supabase.from('l_lessons').select('*').eq('id', next_id).single();

            if(prevError || nextError){
                console.log(prevError, nextError)
                throw prevError || nextError
            }
            return { previousLesson, nextLesson };

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const mergeLessons = createAsyncThunk(
    'lessons/mergeLessons',
    async ({parentLesson, merginLesson}, { rejectWithValue }) => {
        const {html_content, ai_content, id} = merginLesson;
        const {html_content: parent_html_content, ai_content: parent_ai_content, id:parent_id} = parentLesson;
        const new_html_content = parent_html_content + html_content;
        const new_ai_content = parent_ai_content + ai_content;
        try {
            const { data: lesson, error } = await supabase.from('l_lessons').update({html_content: new_html_content, ai_content: new_ai_content}).eq('id', parent_id).single();
            if(error){
                console.log(error)
                throw error
            }
            const { data: deletedLesson, error: deleteError } = await supabase.from('l_lessons').delete().eq('id', id).single();
            if(deleteError){
                console.log(deleteError)
                throw deleteError
            }
            return lesson;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


const lessonSlice = createSlice({
  name: 'iterations',
  initialState: {
    currentLesson: null,
    nextLesson: null,
    previousLesson: null,
    status: 'idle',
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(fetchCurrentLesson.pending, (state) => {
            state.status = 'loading';
            state.loading = true;
        })
        .addCase(fetchCurrentLesson.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.currentLesson = action.payload;
            state.loading = false;
        })
        .addCase(fetchCurrentLesson.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
            state.loading = false;
        })
        .addCase(updateCurrentLesson.pending, (state) => {
            state.status = 'loading';
            state.loading = true;
        })
        .addCase(updateCurrentLesson.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.currentLesson = action.payload;
            state.loading = false;
        })
        .addCase(updateCurrentLesson.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
            state.loading = false;
        })
        .addCase(fetchPreviousAndNextLessons.pending, (state) => {
            state.status = 'loading';
            state.loading = true;
        })
        .addCase(fetchPreviousAndNextLessons.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.previousLesson = action.payload.previousLesson;
            state.nextLesson = action.payload.nextLesson;
            state.loading = false;
        })
        .addCase(fetchPreviousAndNextLessons.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
            state.loading = false;
        })
        .addCase(mergeLessons.pending, (state) => {
            state.status = 'loading';
            state.loading = true;
        })
        .addCase(mergeLessons.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.currentLesson = action.payload;
            state.loading = false;
        })
        .addCase(mergeLessons.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
            state.loading = false;
        });
  },
});

export default lessonSlice.reducer;

import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import Login from './Pages/Login';
import withAuth from './hooks/withAuth';
import withProviders from './hooks/withProvider';
import NotFound from './Pages/NotFound';
import { Routes, Route } from 'react-router-dom';
import PharmacySkillsLesons from './Pages/modules/PharmacySkillsLesons.js';
import UploadQuestions from './Pages/modules/UploadQuestions/index.js'
import ProcessQuestions from './Pages/modules/ProcessQuestions/index';
import LeaderBaord from './Pages/modules/LeaderBoard/LeaderBaord';
import ScreenCapture from './ScreenCapture';
import SignUpAuthor from './Pages/modules/LeaderBoard/SignUpAuthor';
import { Worker } from '@react-pdf-viewer/core';
import Imagify from './Pages/modules/Imagify/Imagify.js';



function App() {

  


  return (
    <div className="App overlay block">
       <ScreenCapture >
       <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
       <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/login" element={<Login />} />
    <Route path="/modules/PharmacySkillsLesons" element={<PharmacySkillsLesons />} />
    <Route path="/modules/UploadQuestions" element={<UploadQuestions />} />
    <Route path="/modules/ProcessQuestions" element={<ProcessQuestions />} />
    <Route path="/modules/LeaderBoard" element={<LeaderBaord />} />
    <Route path="/modules/LeaderBoard/SignUpAuthor" element={<SignUpAuthor />} />
    <Route path="/modules/Imagify/Imagify" element={<Imagify />} />
    {/* This catch-all route should always be last */}
    <Route path="*" element={<NotFound />} />
   
</Routes>
</Worker>
        </ScreenCapture>
    </div>
  );
}

export default withProviders(withAuth(App));


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import openai from '../../services/openai';

// Thunk to interact with GPT-3 Model
export const fetchGptModel = createAsyncThunk('openAi/fetchGptModel', async (prompt) => {
    const {fullPrompt, model, type} = prompt
  const response = await openai.chat.completions.create({
    messages: [{ role: 'user', content: fullPrompt }],
    model: model, // Or whatever the engine ID for GPT-3.5 is
  });
  if(response) {
    const responseText = response.choices[0].message.content
    return {
        responseText,
        type
    }
  }
  return "Yikes Minor issue please try again";
});



// Slice
const openAiSlice = createSlice({
  name: 'openAi',
  initialState: {
    gptResponse: null,
    gptResponseQuestions: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchGptModel.fulfilled, (state, action) => {
        if(action.payload.type === "question"){
            state.gptResponseQuestions = action.payload.responseText;
            state.loading = false;
            state.error = null;
        } else {
          state.gptResponse = action.payload.responseText;
          state.loading = false;
          state.error = null;
        }
      })
        .addCase(fetchGptModel.pending, (state) => {
        state.loading = true;
        })
        .addCase(fetchGptModel.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        })
  },
});

export default openAiSlice.reducer;

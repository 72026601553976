import {
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
    TextField,
    IconButton,
  } from "@mui/material";
  import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
  import EditIcon from "@mui/icons-material/Edit";
  import React from "react";
  import { useDispatch } from "react-redux";
  import { addNewPrompt } from "../../../../state/jobs/prompts";
  import { updatePrompt } from "../../../../state/jobs/prompts";
  
  function PromptDialog({
    defaultP,
    prompts,
    selectedPrompt,
    setSelectedPrompt,
    refetch,
  }) {
    const [promptText, setPromptText] = React.useState(
      selectedPrompt.prompt_text || ""
    );
    const dispatch = useDispatch();
    const [showAddNew, setShowAddNew] = React.useState(false);
    const [showEdit, setShowEdit] = React.useState(false);
    const [newPrompt, setNewPrompt] = React.useState({
        title: "",
        prompt_text: "",
        prompt_for: selectedPrompt.prompt_for,
        });

        const handleAddNewPrompt = async () => {
            await dispatch(addNewPrompt(newPrompt));
            await refetch();
            setShowAddNew(false);
            };

       const handleEditPrompt = async () => {
            const promptToUpdate = { ...selectedPrompt };
            promptToUpdate.prompt_text = promptText;
            promptToUpdate.title = selectedPrompt.title;
            delete promptToUpdate.isDefault;

            await dispatch(updatePrompt({ id: selectedPrompt.id, data: promptToUpdate }));
            await refetch();
            setShowEdit(false);
            };     
  
    const handlePromptChange = (event) => {
      setSelectedPrompt(event.target.value);
      setPromptText(event.target.value.prompt_text);
    };
  
    const handleTextAreaChange = (event) => {
      setPromptText(event.target.value);
      setSelectedPrompt((prev) => ({ ...prev, prompt_text: event.target.value }));
    };
  
    return (
      <Container className="p-4 mx-auto mt-5 shadow-md w-64 rounded-md">
        <div className="flex justify-between mb-4">
          <div>
            <IconButton onClick={() => setShowAddNew(!showAddNew)} size="large">
              <AddCircleOutlineIcon />
            </IconButton>
            <IconButton onClick={() => setShowEdit(!showEdit)} size="large">
              <EditIcon />
            </IconButton>
          </div>
          {selectedPrompt.isDefault && <p>Default</p>}
        </div>
  
        {showAddNew ? (
          <form>
            <TextField
              className="mb-4"
              label="Title"
              variant="outlined"
              fullWidth
            onChange={(e) => setNewPrompt({ ...newPrompt, title: e.target.value })}
               value={newPrompt.title}
            />
            <TextField
              className="mb-4"
              label="Prompt Text"
              variant="outlined"
              multiline
              minRows={8}
              fullWidth
                onChange={(e) => setNewPrompt({ ...newPrompt, prompt_text: e.target.value })}
                value={newPrompt.prompt_text}
            />
         <div className="flex justify-between">
         <Button
              variant="contained"
              color="primary"
              onClick={handleAddNewPrompt}
            >
              Add New
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowAddNew(false)}
            >
              Cancel
            </Button>
         </div>
          </form>
        ) : showEdit ? (
          <form >
            <TextField
              className="mb-4"
              label="Title"
              variant="outlined"
              defaultValue={selectedPrompt.title}
              onChange={(e) => setSelectedPrompt({ ...selectedPrompt, title: e.target.value })}
              fullWidth
            />
            <TextField
              className="mb-4"
              label="Prompt Text"
              variant="outlined"
              multiline
              rows={8}
              value={promptText}
              onChange={(e) => selectedPrompt.prompt_text = e.target.value}
              fullWidth
            />
            <div className="flex justify-between">
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditPrompt}
            >
              Save Changes
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowEdit(false)}
            >
              Cancel
            </Button>
            </div>
          </form>
        ) : (
          <>
            <FormControl className="w-full mb-4">
              <InputLabel id="prompt-select-label">Select a prompt</InputLabel>
              <Select
                labelId="prompt-select-label"
                id="prompt-select"
                value={selectedPrompt}
                onChange={handlePromptChange}
              >
                {prompts && prompts.length > 0 ? (
                  prompts.map((prompt) => (
                    <MenuItem key={prompt.id} value={prompt}>
                      {prompt.title} {prompt.isDefault && "(Default)"}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No Prompts Available</MenuItem>
                )}
              </Select>
            </FormControl>
            <textarea
              className="w-full h-64 resize-none p-4 border-2 border-gray-300 rounded-md"
              value={promptText}
              onChange={handleTextAreaChange}
            />
          </>
        )}
      </Container>
    );
  }
  
  export default PromptDialog;
  
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';

function LeftDrawer({ setPaper, pastPapers, toggleDrawer }) {
  // Check if pastPapers is not ready and show a loading indicator
  if (!pastPapers) {
    return (
      <div className="flex justify-center items-center h-full">
        <CircularProgress />
      </div>
    );
  }

  // If pastPapers is an empty array, show a no data message
  if (pastPapers.length === 0) {
    return (
      <div className="flex justify-center items-center h-full text-gray-500">
        No papers available
      </div>
    );
  }

  return (
    <div className="h-full overflow-auto">
      <List className="p-0">
        {pastPapers.map((paper) => (
          <ListItem 
            button 
            key={paper.id} 
            onClick={() => {
              setPaper(paper)
              toggleDrawer()
            }}
            className="border-b border-gray-300 hover:bg-gray-100"
          >
            <ListItemText primary={paper.file_name} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default LeftDrawer;

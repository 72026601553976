import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, fetchUser } from '../state/user';
import supabase from '../services/supabase';
import { CircularProgress } from '@mui/material';

//creat a loading component center of the screen
const Loading = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </div>
    );
};


function withAuth(WrappedComponent) {
    return function WithAuth(props) {
        const navigate = useNavigate();
        const location = useLocation();
        const dispatch = useDispatch();
        const [isChecking, setIsChecking] = useState(true); // Add a new piece of state to track whether the check is ongoing

        useEffect(() => {
            async function checkSession() {
                try {
                    const {data: session} = await supabase.auth.getSession();
                    if (!session?.session) {
                        if (location.pathname !== '/login') {
                            navigate('/login');
                        }
                    } else {
                        const user = session?.session?.user;
                        if (user) {
                            dispatch(fetchUser(user.id));
                            if (location.pathname === '/login') {
                                navigate('/');
                            }
                        }
                    }
                } catch (error) {
                    console.error('Error checking session:', error);
                } finally {
                    setIsChecking(false); // Set isChecking to false once the check is complete
                }
            }

            checkSession();
        }, [dispatch, navigate, location]);

        useEffect(() => {
            const { data: authListener } = supabase.auth.onAuthStateChange(
                async (event, session) => {
                 
                    
                    const userSignedIn = event === 'SIGNED_IN' && session?.user;
                    const userSignedOut = event === 'SIGNED_OUT';
                    
                    if (userSignedIn) {
                        if (location.pathname === '/login') {
                            navigate('/');
                        }
                    } else if (userSignedOut) {
                        navigate('/login');
                    }
                }
            );
              
            return () => {
                authListener.subscription.unsubscribe();
            };
        }, [navigate, location]);      
          

        if (isChecking) return <Loading />; // If isChecking is true, render nothing

        return <WrappedComponent {...props} />;
    };
}

export default withAuth;

import {useState, useEffect} from 'react'
import withLayout from '../../../hooks/withLayout'

// module components
import Dashboard from './components/Dashboard'
import Preview from './components/Preview'
import DrawerComp from './components/Drawer'

//MUI components
import { Button, Drawer } from '@material-ui/core'


// RTK imports
import { useDispatch, useSelector } from 'react-redux'
import { fetchQuestionPapers, fetchCategories, fetchQuestions } from '../../../state/processQuestions'

function ProcessQuestions({ isFullScreen, setIsFullScreen }) {
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const paperTotal = useSelector((state) => state.processQuestions.paperCount);
  const questionPapers = useSelector((state) => state.processQuestions.questionPapers);
  const categories = useSelector((state) => state.processQuestions.categories);
  const selectedPaper = useSelector((state) => state.processQuestions.selectedPaper);
  const [resetKey, setResetKey] = useState(0);
  const perPage = 10;
  
  const toggleDrawer = () => {
      setDrawerOpen(!drawerOpen);
      }

      useEffect(() => {
        console.log("InputPreview mounted");
        return () => console.log("InputPreview unmounted");
    }, []);
    
      
      const toggleFullscreen = () => {
        setIsFullScreen(prevIsFullScreen => {
          const nextIsFullScreen = !prevIsFullScreen;
    
          if (nextIsFullScreen) {
            document.documentElement.requestFullscreen();
          } else {
            document.exitFullscreen();
          }
    
          return nextIsFullScreen;
        });
      };

    const handlePageChange = (page) => {
      setCurrentPage(page);
      dispatch(fetchQuestionPapers({ page, perPage }));
    };

    useEffect(() => {
      if(selectedPaper){
        dispatch(fetchQuestions(selectedPaper.id))
      }
    }, [dispatch, selectedPaper]);


    useEffect(() => {
      dispatch(fetchCategories());
    }, [dispatch]);

    useEffect(() => {
      dispatch(fetchQuestionPapers({ page: currentPage, perPage }));
    }, [dispatch, currentPage, perPage]);



  
      return (
        <div className="flex flex-col  bg-gradient-to-r from-blue-500 to-yellow-500">
   <div className='backdrop-blur-sm  bg-blue-500'>  
    <Dashboard  onToggleDrawer={toggleDrawer} onToggleScreen={toggleFullscreen} isFullScreen={isFullScreen}/>
    </div> 

    <div className="flex backdrop-blur-sm bg-opacity-80 bg-white w-full">
      <Preview
      categories={categories}
      />
    </div>    

  <div className="flex flex-row h-screen overflow-y-scroll">
    <Drawer open={drawerOpen} onClose={toggleDrawer}  anchor="left"   className="w-640" 
     sx={{ "& .MuiDrawer-paper": { width: '300px' }}}  // Apply the style here using sx prop
    >
      <DrawerComp setDrawerOpen={setDrawerOpen} 
      perPage={perPage}
      currentPage={currentPage}
      handlePageChange={handlePageChange}
      questionPapers={questionPapers}
      paperTotal={paperTotal}
      />
    </Drawer>
  </div>

</div>
      );
}

export default withLayout({ layout: 'default' })(ProcessQuestions)


import React, { useRef, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'

//import MUI 
import { Button, CircularProgress } from '@mui/material';

//rtk
import { useDispatch, useSelector } from 'react-redux';
import { UploadQuestion, clearError, setPreviousEMCQ } from '../../../../state/processQuestions';
import { fetchQuestions } from '../../../../state/processQuestions';



function InputPreview({categories}) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const question = useSelector((state) => state.processQuestions.selectedQuestion);
  const selectedQuestion = useSelector((state) => state.processQuestions.selectedQuestion);
  const selectedPaper = useSelector((state) => state.processQuestions.selectedPaper);
  const capturedImage = useSelector((state) => state.processQuestions.capturedImg);
  const errorMessage = useSelector((state) => state.processQuestions.error);
  const previousEMCQ = useSelector((state) => state.processQuestions.previousEMCQ);
  const loading = useSelector((state) => state.processQuestions.status === 'loading');
  const questionAuthor = question?.authors
  const loggedInAuthor = useSelector((state) => state.user.authorId);
  const [submitting, setSubmitting] = React.useState(false);
  const [selectedText , setSelectedText] = React.useState('');
  const questionIsLocked = questionAuthor?.id !== loggedInAuthor;
  const [imgSrc, setImgSrc] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState('');



  const handleImageUpload = (e) => {
    setImgSrc(URL.createObjectURL(e.target.files[0]));
  };



  const handleImagePaste = () => {
    if (capturedImage) {
      console.log({ capturedImage });
      setImgSrc(capturedImage);
    } else {
      alert('No image captured!');
    }
  };

 
  useEffect(() => {
    const handleSelectionChange = () => {
      const selectedText2 = window.getSelection().toString();
      setSelectedText(selectedText2);
      console.log('Selected text:', selectedText2);
    };

    document.addEventListener('selectionchange', handleSelectionChange);

    // Cleanup: Remove event listener on component unmount
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, []); 

useEffect(() => {
  if (errorMessage) {
    alert(errorMessage);
    dispatch(clearError());
  }
}, [errorMessage]);

const setPreviousEMCQs = () => {
  console.log("PREVIOUS VLAUEs", previousEMCQ)
  if(previousEMCQ){
    formik.setFieldValue('options', previousEMCQ)
  }
}

  const formik = useFormik({
    initialValues: {
      stem: '',
      main_question: '',
      options: {A: '', B: '', C: '', D: '', E: '', F: '', G: '', H: ''},
      hint: '',
      explanation: '',
      answer: '',
      tags: [],
      subcategory_id: null,
      additional_categories: [],
      type: 'MCQ', // MCQ/EMCQ/Calculation
      difficulty: null,
      rating: null,
      category_id: 1,
      raw_paper: null,
      unique_key: null,
      resources: '',
      units: '',
    },
    onSubmit: async (values) => {

      // use manual validation for main_question, options, answer, explanation, hint, tags, difficulty, rating, resources taking into account question type, dont use yup

      console.log("QUESTIONS TYPE ", values)
      if(!values.answer, !values.main_question){

        alert(`please fill in the ${!values.answer ? 'answer' : 'main question'}`);
        setErrorMessages(`please fill in the ${!values.answer ? 'answer' : 'main question'}`);
        return
      }

      if(values.type === 'Calculation' && !values.units){
        alert('please fill in the units');
        setErrorMessages('please fill in the units');
        return
      }
       
      if(values.type === 'MCQ' && !values.options.A && !values.options.B && !values.options.C && !values.options.D && !values.options.E){
        alert('please fill in the options');
        setErrorMessages('please fill in the options, on of them is mising');
        return
      }

      if(values.type === 'EMCQ' && !values.options.A && !values.options.B && !values.options.C && !values.options.D && !values.options.E && !values.options.F && !values.options.G && !values.options.H){
        alert('please fill in the options');
        setErrorMessages('please fill in the options, on of them is mising');
        return
      }
      



      setSubmitting(true);
          values.category_id = values.type === 'Calculations' ? 2 : 1;
          values.raw_paper = selectedPaper.id;
          values.unique_key = `${selectedPaper.id}_${selectedQuestion.qnumber}`;
          values.qnumber = selectedQuestion.qnumber;

         // Handling subcategory_id and additional_categories
         if (values.additional_categories?.length > 0) {
          values.subcategory_id = values.additional_categories[0].value;
          
          // Removing the main category from additional categories if it's present
          values.additional_categories = values.additional_categories
            .filter((cat, index) => index > 0)
            .map(cat => ({ id: cat.value, name: cat.label }));
        }
      

      console.log("ERROR BASED",values);
      setErrorMessages('');
      await dispatch(UploadQuestion([values, imgSrc]));
      if(values.type === 'EMCQ'){
        dispatch(setPreviousEMCQ(values.options))
      }
      await dispatch(fetchQuestions(selectedPaper.id));
      setSubmitting(false);
      formik.setErrors({}); 
    },
  });

  


  useEffect(() => {
    if(selectedPaper && selectedPaper.type === 'Calculations'){
      formik.setFieldValue('type', 'Calculation');
    }
  }, [selectedPaper]);
  
  useEffect(() => {
    if (selectedQuestion && selectedQuestion.type === 'Calculation') {
      console.log("SETTING TYPE FIELD", selectedQuestion.type);
      formik.setFieldValue('type', 'Calculation');
    }
  }, [selectedQuestion]);

  console.log(formik.values);


  let filteredCategories = categories.filter(cat => cat.category_id !== 2);

  if (formik.values.type === 'Calculation') {
    filteredCategories = categories.filter(cat => cat.category_id === 2);
  }

  const options = filteredCategories.map(cat => ({ value: cat.id, label: cat.name }));

  const questionTypeRef = useRef();


  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === " " && selectedText) {
        e.preventDefault();
        const inputs = formRef.current.querySelectorAll("input[name], textarea[name]");
        for (const input of inputs) {
          if (!input.value) {
            const name = input.getAttribute("name");
            console.log("INPUT LOGIC",{ name, selectedText })
            formik.setFieldValue(name, selectedText);
            break;
          }
        }
      }
    };
  
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedText, formik]);

  useEffect(() => {
    questionTypeRef.current.focus();
  }, []);

  useEffect(() => {
    if (question) {
    // console.log(question)
      const { authors, id, options, ...questionWithoutAuthors } = question;

      formik.setValues({
        ...questionWithoutAuthors,
        options: options ? options : { A: '', B: '', C: '', D: '', E: '', F: '', G: '', H: '' },
      });
    }
  }, [question, formik.setValues]);

 

  const handleFormReset = useCallback(() => {
    formik.resetForm();
    // console.log("Resetting form");
    if(selectedPaper && selectedPaper.type === 'Calculations'){
      formik.setFieldValue('type', 'Calculation')
    }
}, [formik]);

  useEffect(() => {
    if (selectedQuestion && !selectedQuestion?.id) {
      handleFormReset();
    }
  }, [selectedQuestion]);

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-2xl font-bold mb-4">
        Input Preview 
        {questionAuthor && questionIsLocked && (
          <span className="text-red-500 text-sm ml-2">
            (locked by {questionAuthor.full_name})
          </span>
        )}
      </h2>
      {
        errorMessages && <div className="text-red-500">{errorMessages}</div>
      }
      {questionAuthor && questionIsLocked && (
        <div className="absolute inset-0 bg-black bg-opacity-50 z-10 flex items-center justify-center">
          <span className="text-white text-2xl">
            This input is locked
          </span>
        </div>
      )}

      <form ref={formRef} className="flex flex-col h-full overflow-auto">
        <div className="flex flex-row mb-4">
          <label htmlFor="type" className="text-gray-700 font-medium pr-2">
            Question Type
          </label>
          <select
            id="type"
            name="type"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.type}
            ref={questionTypeRef}
            className="border border-gray-400 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
          >
            <option value="MCQ" label="MCQ" />
            <option value="EMCQ" label="EMCQ" />
            <option value="Calculation" label="Calculation" />
          </select>
        </div>

        <div className="flex flex-row mb-4 ">
          <label htmlFor="stem" className="text-gray-700 font-medium pr-2">
            Stem
          </label>

          <div className="quill-editor-wrapper w-full" style={{backgroundColor: 'white', padding: '10px', marginBottom: '10px'}}>
            <div className="w-full">
              <ReactQuill
                value={formik.values.stem}
                onChange={(content) => {
                  const isEmptyVisualContent = ["<p><br></p>", "<div><br></div>"].includes(content);
                  formik.setFieldValue('stem', isEmptyVisualContent ? "" : content);
                }}
              />
            </div>
            {/* Hidden Input */}
            <input 
              type="hidden" 
              name="stem" 
              value={formik.values.stem} 
            />
          </div>
          {formik.touched.stem && formik.errors.stem ? (
            <div className="text-red-500">{formik.errors.stem}</div>
          ) : null}
        </div>

        <div className="flex flex-row mb-4">
          <label htmlFor="main_question" className="text-gray-700 font-medium">
            Main Question
          </label>
          <textarea
            id="main_question"
            name="main_question"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.main_question}
            className="border border-gray-400 rounded-md w-full py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500 resize-none h-24"
          />
          {formik.touched.main_question && formik.errors.main_question && (
          <div className="text-red-500">{formik.errors.main_question}</div>
              )}
        </div>

{
  formik.values.type === "EMCQ" && previousEMCQ && (
    <Button onClick={setPreviousEMCQs}>
      Use Previous EMCQ
    </Button>
  )
}
        {(formik.values.type === 'MCQ' || formik.values.type === 'EMCQ') && (
  <>
    {['A', 'B', 'C', 'D', 'E', ...(formik.values.type === 'EMCQ' ? [ 'F', 'G', 'H'] : [])].map(
      (option) => (
        <div key={option} className="flex flex-col mb-4">
          <label htmlFor={`options.${option}`} className="text-gray-700 font-medium">
            Option {option}
          </label>
          <textarea
            type="text"
            id={`options.${option}`}
            name={`options.${option}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.options[option] || ''}
            className="border border-gray-400 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500 h-16"
          />
         {formik.touched.options?.[option] && formik.errors.options?.[option] && (
  <div className="text-red-500">{formik.errors.options[option]}</div>
)}

        </div>
      )
    )}
  </>
)}

<div className='flex justify-row mb-4 w-full'>
<label htmlFor="answer" className="text-gray-700 font-medium pr-3">
            Answer
          </label>
          <textarea
            type="text"
            id="answer"
            name="answer"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.answer}
            className="border border-gray-400 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500 mr-4 "
          />

</div>


{formik.values.type === 'Calculation' && (
        <div className="flex flex-row mb-4">
        
           {formik.touched.answer && formik.errors.answer ? (
            <div className="text-red-500">{formik.errors.answer}</div>
          ) : null}
      
            <>
              <label htmlFor="units" className="text-gray-700 font-medium pr-3">
                Units
              </label>
              <input
                type="text"
                id="units"
                name="units"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.units}  
                className="border border-gray-400 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500"
              />
            </>
    
          {formik.touched.units && formik.errors.units ? (
            <div className="text-red-500">{formik.errors.units}</div>
          ) : null}
        </div>
          )}

        <div className="flex flex-row mb-4">
          <label htmlFor="explanation" className="text-gray-700 pr-4 font-medium">
            Explanation
          </label>
          <textarea
            type="text"
            id="explanation"
            name="explanation"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.explanation}
            className="border border-gray-400 rounded-md py-2 px-3 w-full text-gray-700 focus:outline-none h-32 focus:border-blue-500"
          />
          {formik.touched.explanation && formik.errors.explanation ? (
            <div className="text-red-500">{formik.errors.explanation}</div>
          ) : null}
        </div>

        <div className="flex flex-row mb-4">
          <label htmlFor="hint" className="text-gray-700 font-medium pr-4">
            Hint
          </label>
          <input
            type="text"
            id="hint"
            name="hint"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.hint}
            className="border border-gray-400 rounded-md py-2 px-3 w-full text-gray-700 focus:outline-none focus:border-blue-500"
          />
          {formik.touched.hint && formik.errors.hint ? (
            <div className="text-red-500">{formik.errors.hint}</div>
          ) : null}
        </div>

        

        <div className="flex flex-row mb-4 ">
          <label htmlFor="tags" className="text-gray-700 font-medium pr-4">
            Tags
          </label>
          <input
            type="text"
            id="tags"
            name="tags"
            onChange={(e) => formik.setFieldValue('tags', e.target.value.split(',').map(tag => tag.trim()))}
            onBlur={formik.handleBlur}
            value={formik.values.tags?.join(', ')}
            className="border border-gray-400 rounded-md py-2 px-3 w-full text-gray-700 focus:outline-none focus:border-blue-500"
          />
          {formik.touched.tags && formik.errors.tags ? (
            <div className="text-red-500">{formik.errors.tags}</div>
          ) : null}
        </div>

        <div className="flex flex-row mb-4">
          <label htmlFor="category" className="text-gray-700 pr-3 font-medium">
            Category
          </label>
          <Select
            isMulti
            name="category"
            options={options}
            className="w-full"
            onChange={(selectedOptions) => {
              formik.setFieldValue(
                'additional_categories',
                selectedOptions ? selectedOptions : []
              );
            }}
            onBlur={() => formik.setFieldTouched('additional_categories', true)}
            value={formik.values.additional_categories}
          />
          {formik.touched.additional_categories && formik.errors.additional_categories ? (
            <div className="text-red-500">{formik.errors.additional_categories}</div>
          ) : null}
        </div>

        <div className="flex flex-row mb-4 justify-between">
          <div className="flex flex-row pr-4">
            <label htmlFor="difficulty" className="text-gray-700 font-medium pr-4">
              Difficulty
            </label>
            <input
              type="number"
              id="difficulty"
              name="difficulty"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.difficulty}
              className="border border-gray-400 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500 w-24"
            />
            {formik.touched.difficulty && formik.errors.difficulty ? (
              <div className="text-red-500">{formik.errors.difficulty}</div>
            ) : null}
          </div>

          <div className="flex flex-row">
            <label htmlFor="rating" className="text-gray-700 font-medium pr-4">
              Rating
            </label>
            <input
              type='number'
              id="rating"
              name="rating"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.rating}
              className="border border-gray-400 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500 w-24"
            />
            {formik.touched.rating && formik.errors.rating ? (
              <div className="text-red-500">{formik.errors.rating}</div>
            ) : null}
          </div>
        </div>

        <div className="flex flex-row mb-4">
          <label htmlFor="resources" className="text-gray-700 font-medium pr-4">
            Resources
          </label>
          <input
            className="border border-gray-400 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500 mr-2"
            id="resources"
            name="resources"
            label="Resources"
            value={formik.values.resources}
            onChange={formik.handleChange}
          />
          {formik.touched.resources && formik.errors.resources ? (
            <div className="text-red-500">{formik.errors.resources}</div>
          ) : null}
        </div>

        <div className="flex flex-row mb-4">
          <label htmlFor="image" className="text-gray-700 font-medium pr-4">
            Image
          </label>
          <div className="flex flex-col">
            <div className="flex flex-row mb-2">
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleImageUpload}
                className="border border-gray-400 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500 mr-2"
              />
              <button
                type="button"
                onClick={handleImagePaste}
                className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md"
              >
                From Clipboard
              </button>
            </div>
            {imgSrc && (
              <img
                src={imgSrc}
                alt="Preview"
                className="max-w-full h-auto"
              />
            )}
          </div>
        </div>

        <button
          type="submit"
          onClick={formik.handleSubmit}
          className="bg-blue-500 hover:bg-blue-600 text-white w-full py-2 px-4 rounded-md mt-auto"
          disabled={submitting && loading }
        >
          {submitting && loading ? (
            <CircularProgress size={24} className="text-white" />
          ) : (
            'Submit'
          )}
        </button>
      </form>
    </div>
  );
}

export default InputPreview;
import React from 'react';
import LeftSidebar from './defaultLayoutComponents/LeftSideBar';
import MainContent from './defaultLayoutComponents/MainComponent';
import TopNavbar from './defaultLayoutComponents/NavBar';
import { useSelector } from 'react-redux';

function DefaultLayout({ children }) {
  const isFullScreen = useSelector((state) => state.SnackBar.isFullScreen);

  return (
    <div className={`flex h-screen bg-gray-100 ${isFullScreen ? 'fullscreen' : ''}`}>
      {!isFullScreen && (
        <div className="bg-white min-h-screen flex flex-col">
          <LeftSidebar />
        </div>
      )}
      <div className="flex-1 flex flex-col">
        {!isFullScreen && (
          <header className="flex items-center justify-between bg-white border-b">
            <TopNavbar />
          </header>
        )}
        <main className={`flex-1 bg-gray-200 px-6 py-4 ${isFullScreen ? 'mt-0' : 'mt-10'} min-h-screen`}>
          <MainContent>
            {children}
          </MainContent>
        </main>
      </div>
    </div>
  );
}

export default DefaultLayout;

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appendMessage, addSystemMessage } from '../../../state/imagify/imagifySlice.js';
import supabase from '../../../services/supabase.js';

function Chat() {
  const messages = useSelector(state => state.imagifySlice.messages);
  const loading = useSelector(state => state.imagifySlice.loading);
  const currentQuestions = useSelector(state => state.imagifySlice.imagifyQuetions);
  const systemMessage = useSelector(state => state.imagifySlice.systemMessage);
  const [newMessage, setNewMessage] = useState('');
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null); // Create a ref

  console.log("ALL MESSAGES", messages)
    // Function to scroll to the bottom
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }

  const handleMessageSubmit = async () => {
    const newMessageObject = {
        role: 'user',
        content: [
            {
            type: 'text',
            text: newMessage,
            },
        ],
        };
        const newMessages = [...messages, newMessageObject];
        dispatch(appendMessage(newMessageObject));
    const response = await supabase.functions.invoke('pdfSplitter', 
    {
        body: {
            messages: newMessages,
        }
    }) 
      
    console.log("RESPONSE FROM CHAT", response)
    const apiMessage = response?.data?.choices[0]?.message
    
    try {
        const apiResponseArray = JSON.parse(apiMessage);
        const updatedQuestions = [...currentQuestions]; // Create a copy of currentQuestions

        apiResponseArray.forEach(apiQuestion => {
          // Add the required flags
          apiQuestion.author_id = 25;
          apiQuestion.pendingSubmit = true;
          // Parse qnumber from string to number
          const apiQuestionNumber = parseInt(apiQuestion.qnumber, 10);
          // Find and update the existing question or add a new one
          const existingQuestionIndex = updatedQuestions.findIndex(q => q.qnumber === apiQuestionNumber);
          if (existingQuestionIndex !== -1) {
              // Merge all properties except qnumber
              updatedQuestions[existingQuestionIndex] = { 
                  ...updatedQuestions[existingQuestionIndex], 
                  ...apiQuestion,
                  qnumber: updatedQuestions[existingQuestionIndex].qnumber // Keep the original numeric qnumber
              };
          } else {
              // For new questions, set qnumber as a number
              apiQuestion.qnumber = apiQuestionNumber;
              updatedQuestions.push(apiQuestion);
          }
        });
    } catch (error) {
        console.log("MALFORMED OUTPUT CANNOT BE PARSED", apiMessage);
        dispatch(addSystemMessage("Error parsing JSON, please check your output and try again"))
        // Handle or log the error appropriately
    }

    if (apiMessage){
    dispatch(appendMessage(apiMessage));
    } else {
        dispatch(appendMessage({
            role: 'assistant',
            content: 'Error producing a repsonse.',
        }));
    }
    
    setNewMessage('');
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="max-w-md mx-auto border p-4">
      <div className="h-96 overflow-y-auto mb-4">
        {messages.map((message, index) => (
          <div key={index} className={`my-1 p-2 rounded ${message.role === 'user' ? 'bg-blue-100 text-left' : 'bg-green-100 text-right'}`}>
            <span>{message.role === 'user' ? 'You: ' : 'Assistant: '}</span>
            <span>
      {Array.isArray(message.content) ?
        message.content.map(item => item.text).join(' ') :
        message.content}
    </span>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="flex gap-2">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message here..."
          className="flex-grow p-2 border rounded"
        />
        {
            loading ? <span className="animate-pulse">{systemMessage}</span> : null
        }
        <button onClick={handleMessageSubmit} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          Send
        </button>
      </div>
    </div>
  );
}

export default Chat;

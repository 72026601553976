import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

const initialState = {
    aIVoiceOver: null,
    status: 'idle',
    error: null,
    loading: false,
};

export const fetchAIVoiceOver = createAsyncThunk('aIVoiceOver/fetchAIVoiceOver', async () => {
    const { data, error } = await supabase.from('aIVoiceOver').select('*').single();
    if (error) {
        throw error;
    }
    return data;
});

const aIVoiceOverSlice = createSlice({
    name: 'aIVoiceOver',
    initialState,
    reducers: {
        setAIVoiceOver: (state, action) => {
            state.aIVoiceOver = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAIVoiceOver.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAIVoiceOver.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.aIVoiceOver = action.payload;
            })
            .addCase(fetchAIVoiceOver.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }   
});

export const { setAIVoiceOver } = aIVoiceOverSlice.actions;

export default aIVoiceOverSlice.reducer;
// processQuestionsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

// Async thunk for fetching questions from an API
export const fetchQuestionPapers = createAsyncThunk(
    'processQuestions/fetchQuestionPapers',
    async (params) => {
    //   console.log("FETCHING PAPERS", {params})
      const {page, perPage} = params
      const offset = (page - 1) * perPage
      const limit = perPage
      const { data, error } = await supabase
        .from('past_papers')
        .select('*, past_paper_answers(*), past_paper_resources(*), questions(count)')
        .range(offset, offset + limit - 1)

        const { count, error: countError } = await supabase
        .from('past_papers')
        .select('*', { count: 'exact', head: true })
      
      if (error ) {
        console.log(error )
        throw error;
      }
     
      
    //   console.log({ data, count })
      return { data, count: count };
    }
  );



  export const fetchCategories = createAsyncThunk(
    'processQuestions/fetchCategories',
    async () => {
      const { data, error } = await supabase
        .from('subcategories')
        .select('*')
      
      if (error ) {
        console.log(error )
        throw error;
      }

        return { data };
    }
    );

    export const fetchQuestions = createAsyncThunk(
        'processQuestions/fetchQuestions',
        async (paperId) => {
            const { data, error } = await supabase
                .from('questions')
                .select('*, authors(*)')
                .eq('raw_paper', paperId)

            if (error ) {
                console.log(error )
                throw error;
            }

            return { data };
        }
    );

    export const flagCurrentQuetion = createAsyncThunk(
      'processQuestions/flagCurrentQuetion',
      async (params, {dispatch}) => {
        const { questionId, paperId, flag } = params
        console.log("FLAGGING QUESTION", {params})
        const { data, error } = await supabase
          .from('questions')
          .update({ flagged: flag })
          .eq('id', questionId)
          .select('*')
        
        if (error ) {
          console.log(error )
          throw error;
        }
  
        dispatch(fetchQuestions(paperId))
          return { data };
      }
      );

    async function urlToFile(url, filename, mimeType) {
        const response = await fetch(url);
        const blob = await response.blob();
        return new File([blob], filename, { type: mimeType });
      }
      
    

  export const UploadQuestion = createAsyncThunk(
    'processQuestions/UploadQuestion',
    async (payload, thunkAPI) => {
      console.log("UPLOADING QUESTION", {payload})
      const selectedPaper = thunkAPI.getState().processQuestions.selectedPaper
      const authorId = thunkAPI.getState().user.authorId
      console.log({authorId})
      const [values, imgSrc] = payload
      let rosources = null
      if (imgSrc) {

        const file = await urlToFile(
            imgSrc,
            `resources/${selectedPaper.id + values.qnumber}.jpg`,
            'image/jpeg'  // MIME type
          );

        const { data, error } = await supabase.storage
          .from('past_papers')
          .upload(`resources/${selectedPaper.id+values.qnumber}.jpg`, file, {upsert: true})
        if (error ) {
          console.log(error )
          throw error;
        }
        const resourceUrl = supabase.storage.from('past_papers').getPublicUrl(`resources/${selectedPaper.id}.jpg`);
        values.resources = resourceUrl.data.publicUrl
      } 
      console.log("VALUES ERROR", values)
      const uploadData = {
        ...values,
        raw_paper: selectedPaper.id,
        unique_key: `${selectedPaper.id}_${values.qnumber}`,
        author_id: authorId

      }
      const { data, error } = await supabase
        .from('questions')
        .upsert(uploadData, {onConflict: 'unique_key'})
        .select('*')
      
      if (error ) {
        console.log(error )
        throw error;
      }
     
      //After inserting data or updating there is a trigger funciton to update the leader board
      // console.log({ data })
      return { data };
    }
  );

// Initial state
const initialState = {
  questionPapers: [],
  paperCount: 0,
  questionCount: 0,
  selectedPaper: null,  
  questions: [],
  selectedQuestion: null,
  status: 'idle',  // idle | loading | succeeded | failed
  error: null,
  categories : [],
  capturedImg: null,
  previousEMCQ: null,
};

// Slice
const processQuestionsSlice = createSlice({
  name: 'processQuestions',
  initialState,
  reducers: {
    setSelectedPaper: (state, action) => {
      state.selectedPaper = action.payload;
    },
    setSelectedQuestion: (state, action) => {
      state.selectedQuestion = action.payload;
    },
    setCapturedImg: (state, action) => {
      state.capturedImg = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    setPreviousEMCQ: (state, action) => {
      state.previousEMCQ = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchQuestionPapers.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchQuestionPapers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add any fetched questions to the array
        state.questionPapers = action.payload.data;
        state.paperCount = action.payload.count;
      })
      .addCase(fetchQuestionPapers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
        .addCase(UploadQuestion.pending, state => {
            state.status = 'loading';
        })
        .addCase(UploadQuestion.fulfilled, (state, action) => {
            state.status = 'succeeded';
            // Add any fetched questions to the array
            state.selectedQuestion = action.payload.data;
        })
        .addCase(UploadQuestion.rejected, (state, action) => {
          console.log(action.error)
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(fetchCategories.fulfilled, (state, action) => {
            state.status = 'succeeded';
            // Add any fetched questions to the array
            state.categories = action.payload.data;
        })
        .addCase(fetchQuestions.pending, state => {
            state.status = 'loading';
        })
        .addCase(fetchQuestions.fulfilled, (state, action) => {
            state.status = 'succeeded';
            // Add any fetched questions to the array
            state.questions = action.payload.data;
        })
        .addCase(fetchQuestions.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(flagCurrentQuetion.fulfilled, state => {
            state.selectedQuestion.flagged = !state.selectedQuestion.flagged
            state.status = 'succeeded';
        })
  }
});


// Export the actions created by the slice (if any)
export const {
    setSelectedPaper,
    setSelectedQuestion,
    setCapturedImg,
    clearError,
    setPreviousEMCQ
} = processQuestionsSlice.actions;

// Export the reducer
export default processQuestionsSlice.reducer;

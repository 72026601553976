import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

const initialState = {
    user: null,
    status: 'idle',
    error: null,
    loading: false,
    authorAvatar: null,
    authorName: null,
    authorId: null,
};

export const signIn = createAsyncThunk('user/signIn', async (data) => {
    const { email, password } = data;
    const { data:user, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
        console.log(error)
        throw error;
    }
    let userData = null
    if (user) {
    userData = user.user
    }
    // console.log(user)
    return userData;
});



export const uploadAvatarAndAddAuthor = createAsyncThunk(
    'authors/addAuthorWithAvatar',
    async (values, thunkAPI) => {
      const userID = thunkAPI.getState().user.user.id;
      console.log({userID});
      try {
        // Ensure the avatar value has the expected format
        if (!values.avatar.includes(',')) {
          throw new Error('Invalid avatar format');
        }
  
        console.log("VALUES", values)
        // 1. Upload the avatar to the avatars bucket
        const fileExtension = values.avatar.split(';')[0].split('/')[1]; // Extract the file extension
        const filePath = `${userID}.${fileExtension}`;
        const base64Data = values.avatar.split(',')[1];
        const contentType = values.avatar.split(';')[0].split(':')[1];

        console.log({filePath,  contentType, fileExtension})
        // Convert Base64 to Blob
        const binaryData = new Blob([Uint8Array.from(atob(base64Data), char => char.charCodeAt(0))], {type: contentType});
  
        // Upload Blob to Supabase
        const uploadResponse = await supabase.storage.from('author_avatars').upload(filePath, binaryData, {
          contentType: contentType,
          upsert: true
        });
  
        // Check for upload errors
        if (uploadResponse.error) {
          console.log(uploadResponse.error);
          throw uploadResponse.error;
        }
  
        // 2. Retrieve the public URL
        const { data: response, error: getUrlError } = await supabase.storage.from('author_avatars').getPublicUrl(filePath);
        if (getUrlError) {
          throw getUrlError;
        }
  
        const publicURL = response.publicUrl;
  
        // 3. Insert/update the author
        const { data, error } = await supabase.from('authors').upsert({
          full_name: values.fullName,
          avatar_url: publicURL,
          user: userID
        }, { onConflict: 'user' }).select('*').single();
  
        if (error) {
          console.log(error);
          throw error;
        }
  
        console.log("AUTHOR RETURNING DATA", data, data.full_name);
        return data;
      } catch (error) {
        console.log(error);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
);



export const signout = createAsyncThunk('user/signout', async () => {
    const { error } = await supabase.auth.signOut()
    if (error) {

        console.log("sign ourt")
        throw error;
    }
});

export const fetchUser = createAsyncThunk('user/fetchUser', async (id) => {
    const { data, error } = await supabase.from('profiles').select('*, authors(*)').eq('id', id).single();
    if (error) {
        console.log(error)
        throw error;
    }
  
    return data;
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        clearUser: (state) => {
            state.user = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
        
                const author = action.payload.authors;
                console.log({author}, action.payload)
                state.status = 'succeeded';
                state.user = action.payload;
                state.authorAvatar = author?.avatar_url;
                state.authorName = author?.full_name;
                state.authorId = author?.id;
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(signIn.pending, (state) => {
                state.status = 'loading';
                state.loading = true;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload;
                state.loading = false;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.loading = false;
            }
        )
            .addCase(signout.pending, (state) => {
                state.status = 'loading';
            })  
            .addCase(signout.fulfilled, (state) => {
                state.status = 'idle';
                state.user = null;
            })
            .addCase(signout.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(uploadAvatarAndAddAuthor.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(uploadAvatarAndAddAuthor.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.authorAvatar = action.payload.avatar_url;
                state.authorName = action.payload.full_name;
            })
            .addCase(uploadAvatarAndAddAuthor.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
import React,{useState, useRef} from 'react'
import withLayout from '../../../hooks/withLayout'

// module components
import Dashboard from './components/Dashboard'
import UploadForm from './components/UploadForm'
import SideDrawer from './components/SideDrawer'
import FilePreview from './components/FilePreview'

//MUI components
import { Button, Drawer } from '@material-ui/core'

// RTK imports
import { useSelector } from 'react-redux'
import dashBoardSlice from '../../../state/uploadQuestions/dashBoardSlice'

function UploadQuestions() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const fileRef = useRef([]); // To hold the actual File objects
    const [questionPaper, setQuestionPaper] = useState(null);
    const [answerPaper, setAnswerPaper] = useState(null);
    const [preview, setPreview] = useState(null); // store preview URL
    const [isQuestionPreview, setIsQuestionPreview] = useState(true); // toggle preview
    const [formName, setFormName] = useState(''); // store form name
    const [validationMessages, setValidationMessages] = useState([]);
    const loading = useSelector((state) => state.UploadQDashboard.uploading);

    const toggleDrawer = () => {
      setDrawerOpen(!drawerOpen);
    };
    

    React.useEffect(() => {
      if(questionPaper) {
        console.log(questionPaper.name)
        setFormName(questionPaper.name)
      }

    }, [questionPaper])

    const resetAndreload = () => {
      setQuestionPaper(null);
      setAnswerPaper(null);
      setPreview(null);
      setIsQuestionPreview(true);
      setFormName('');
      setValidationMessages([]);
    }

    if(loading) {
      return (
        <div className="flex flex-col h-screen">
          <div className="flex flex-row flex-grow">
            <p>upload to supabase ... please wait </p>
          </div>
        </div>
      );
    }

    return (
        <div className="flex flex-col h-screen">
                <Button onClick={toggleDrawer} className="self-start mt-2 mb-2">
            Toggle Side Drawer
          </Button>
          <Dashboard onToggleDrawer={toggleDrawer} fileRef={fileRef}
          questionPaper={questionPaper}
          answerPaper={answerPaper}
          isQuestionPreview={isQuestionPreview}
          setQuestionPaper={setQuestionPaper}
          setAnswerPaper={setAnswerPaper}
          setPreview={setPreview}
          setIsQuestionPreview={setIsQuestionPreview}
          validationMessages={validationMessages}
          setValidationMessages={setValidationMessages}
          reset={resetAndreload}
          preview={preview}
          />
          <div className="flex flex-row flex-grow">
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}  className="w-640" >
              <SideDrawer  />
            </Drawer>
            <div className="flex-initial w-1/3">
              <UploadForm fileRef={fileRef} paperName={formName} 
              setValidationMessages={setValidationMessages}
              questionPaper={questionPaper}
              answerPaper={answerPaper}
              reset={resetAndreload}
              />
            </div>
            <div className="flex-grow">
              <FilePreview fileRef={fileRef} preview={preview}/>
            </div>
          </div>
      
        </div>
      );
}

export default withLayout({ layout: 'default' })(UploadQuestions)
import React from 'react';
import { Button, Switch, Dialog, DialogActions, DialogContent, CircularProgress } from '@mui/material';
import PromtDialog from './PromtDialog'
import { useSelector, useDispatch } from 'react-redux';
import { updateDefaultPrompt, updatePrompt } from '../../../../state/jobs/prompts';
import { fetchGptModel } from '../../../../state/jobs/openAiSlice';
import { updateCurrentLesson } from '../../../../state/jobs/lessonSlice';
import { updateJobLessons } from '../../../../state/jobs/lessonJob';


function Rewrite({ sourceText, aiText, prompts, selectedPrompt, defaultP, refetch, model, selectedJob, currentIteration}) {
    const dispatch = useDispatch();
    const filteredPrompts = prompts?.filter((prompt) => prompt.prompt_for === "rewrite")
    const [text, setText] = React.useState(sourceText || "")
    const [displayText, setDisplayText] = React.useState(sourceText?.html_content || "pending")
    const gptLoading = useSelector(state => state.openAi.loading)
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const [selectedPromptDropDown, setSelectedPromptDropDown] = React.useState(defaultP ? defaultP : "");
    const [aITextAreaValue, setAiTextAreaValue] = React.useState(sourceText?.ai_content || "");
    const isDefault = selectedPromptDropDown?.isDefault
    const chatGptResponse = useSelector(state => state.openAi.gptResponse)

    React.useEffect(() => {
      
      if(
        selectedJob?.ai_rewrite === currentIteration.id 
      ){
        setAiTextAreaValue(sourceText?.ai_content);
      }
     else {
        setAiTextAreaValue("This rewrite hasnt been done");
      }
  }, [sourceText]);
  


const handleMakeDefault = async () => {
    const data = { id:selectedPromptDropDown.id, title:'rewrite', columnTitle:'rewritePromt' }
    await dispatch(updateDefaultPrompt(data))
    await refetch()
}

const handleGenerateAIResponse = async () => {
    const prompt = selectedPromptDropDown?.prompt_text
    const text = sourceText?.ai_content
    const fullPrompt = `${prompt}.   ${text}`
    console.log({fullPrompt})
    const response = await dispatch(fetchGptModel({fullPrompt, model}))
    console.log(response)
}

const handleSaveAndUse = async () => {
    // make a copy of selectedPromptDropDown and delete isDefault property
    const promptToUpdate = { ...selectedPromptDropDown };
    delete promptToUpdate.isDefault;
    
    console.log(promptToUpdate);
    await dispatch(updatePrompt({ id: selectedPromptDropDown.id, data: promptToUpdate }));
    await refetch();
    dispatch(selectedPrompt(selectedPromptDropDown));

}

React.useEffect(() => {
    setAiTextAreaValue(gptLoading ? `Fetching value for the following prompt..." \n\n +${selectedPromptDropDown?.prompt_text}` : chatGptResponse);
  }, [gptLoading, selectedPromptDropDown, chatGptResponse]);
  


   React.useEffect(() => {
    if(sourceText){
        setText(sourceText)
    }
    }, [sourceText])

    React.useEffect(() => {
        if(text){
            setDisplayText(text?.html_content)
        }
    }, [text])

    const toggleSourceText = () => {
        if(displayText === sourceText?.html_content){
            setDisplayText(sourceText.ai_content)
        } else {
            setDisplayText(sourceText?.html_content)
        }
    }

    const handleSourceTextChange = (e) => {
        setDisplayText(e.target.value);
    };

    const handleSaveChanges = async () => {
        if(displayText === sourceText?.html_content){
            const data = { id: sourceText.id, html_content: displayText }
            await dispatch(updateCurrentLesson(data))
        } else {
            const data = { id: sourceText.id, ai_content: displayText }
            await dispatch(updateCurrentLesson(data))
        }
    }

    const handleSaveToDataBase = async () => {
        if(!chatGptResponse){
            alert("Please generate AI response first")
            return
        }
        const data = { id: sourceText.id, ai_content: chatGptResponse }
        await dispatch(updateCurrentLesson(data))
        await dispatch(updateJobLessons({id: selectedJob?.id, ...selectedJob,  ai_rewrite: currentIteration.id}))
        await refetch()
        alert("Saved to database")
    }

// Handling the change event of textarea
const handleAiTextAreaChange = (e) => {
    setAiTextAreaValue(e.target.value);
}

    

  return (
    <div style={{ display: 'flex', height: '100vh', gap: '1rem' }}>
      
      <div style={{ flex: 1, flexDirection: 'column' }}>
        <div>
        <h2 style={{ textAlign: 'center' }}>Source</h2>
            <button onClick={toggleSourceText}>Toggle Source Text</button>
          
            <Switch
                checked={displayText === sourceText?.html_content}
                onChange={toggleSourceText}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
              <Button mode='outlined' onClick={() => handleSaveChanges()}>Save Changes</Button>
        </div>
       
        <textarea 
          style={{ width: '100%', height: '90%', resize: 'none', padding: '1rem',  border:'2px solid black' }} 
          defaultValue={displayText}
          value={displayText}
          onChange={handleSourceTextChange}
        />
      </div>
      
      <div style={{ flex: 1, flexDirection: 'column' }}>
        <h2 style={{ textAlign: 'center' }}>AI Content</h2>
        
       
        <div>   
              <Button mode='outlined' onClick={() => handleSaveToDataBase()}>Save To DataBase</Button>
              <Button mode='outlined' onClick={()=>{setIsDialogOpen(true)}}>View Prompt</Button>
              <Button mode='outlined' onClick={() => handleGenerateAIResponse()}>Generate</Button>
        </div>
        <div style={{ position: 'relative', width: '100%', height: '90%' }}>
        <div style={{ position: 'relative', width: '100%', height: '90%' }}>
  <textarea
    style={{
      width: '100%',
      height: '100%',
      resize: 'none',
      padding: '1rem',
      border: '2px solid black',
      color: gptLoading ? '#999' : 'initial',
      fontStyle: gptLoading ? 'italic' : 'normal',
      opacity: gptLoading ? 0.5 : 1
    }}
    value={aITextAreaValue}
    onChange={handleAiTextAreaChange}
    disabled={gptLoading}
  />
  {gptLoading && (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 2
    }}>
      <CircularProgress />
    </div>
  )}
</div>
        </div>
        </div>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogContent>
            <PromtDialog defaultP={defaultP} title="Rewrite promt" prompts={filteredPrompts} selectedPrompt={selectedPromptDropDown} setSelectedPrompt={setSelectedPromptDropDown}
            refetch={refetch}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleSaveAndUse()}>Save</Button>
            <Button onClick={() => handleMakeDefault()} disabled={isDefault}>Make Default</Button>
            <Button onClick={() => setIsDialogOpen(false)}>Close</Button>
        </DialogActions>
        </Dialog>

    </div>
  );
}

export default Rewrite;

import Paper from '@mui/material/Paper';

function MainContent({ children }) {
  return (
   
        <Paper className="bg-white p-1 rounded shadow-lg min-h-screen">
          {children}
        </Paper>

  );
}

export default MainContent;

// googleSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

// Async thunk for fetching questions from an API
export const saveOcrText = createAsyncThunk(
  'google/saveOcrText',
  async (payload) => {
    const {ocrText, paperId} = payload;
    console.log("PAYLOAD", payload)
    const { data, error } = await supabase
      .from('past_papers')
      .update({ ocr: ocrText })
      .eq('id', paperId)
      .select('id, ocr');

    if (error) {
      console.log("ERROR", error)
      throw error;
    }
    console.log("DATA")
    return data;
  }
);


// Initial state
const initialState = {
  questions: [],
  status: 'idle',  // idle | loading | succeeded | failed
  error: null,
  ocrText: null,
};

// Slice
const googleSlice = createSlice({
  name: 'google',
  initialState,
  reducers: {
    // Your regular synchronous reducers go here
  },
  extraReducers: builder => {
    builder
      .addCase(saveOcrText.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(saveOcrText.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.ocrText = action.payload;
      })
      .addCase(saveOcrText.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

  }
});

// Export the async thunk (optional: if you want to use it outside of this file)
// export { fetchQuestions };

// Export the actions created by the slice (if any)
export const {} = googleSlice.actions;

// Export the reducer
export default googleSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';
import getPrompt from './prompt';
import { systemPrompt } from './prompt.js';


const initialState = {
    pastPapers: [],
    selectedPaper: null,
    imagifyQuetions: [],
    selectedImagifyQuestion: null,
    paperImages: [],
    selectedPaperImage: null,
    loading: false,
    selectedGroupImages: [],
    selectedImages: [],
    selectedQuestions: [],
    messages : [],
    openDialog: false,
    systemMessage: "",
};

export const fixJson = createAsyncThunk('imagify/fixJson', async (
    _, {dispatch, getState} ) => {
        console.log("FIXING JSON")
        const allMessages = getState().imagifySlice.messages;
        const messages = allMessages.slice(-1);
        console.log("got MESSAGES")
        const currentQuestions = getState().imagifySlice.imagifyQuetions;
        console.log("got currentQuestions")
        const newMessageObject = {
            role: 'user',
            content: [
                {
                type: 'text',
                text: "Please fix the json from the last response",
                },
            ],
            };
            console.log("got newMessageObject", newMessageObject)
            const newMessages = [...messages, newMessageObject];
            dispatch(appendMessage(newMessageObject));
            dispatch(addSystemMessage("Attempting to fix json wait for response"));
            console.log("got newMessages", newMessages)
        const response = await supabase.functions.invoke('gpt4turbo', 
        {
            body: {
                messages: newMessages,
            }
        }) 
          
        console.log("RESPONSE FROM CHAT", response)
        const apiMessage = response?.data?.choices[0]?.message?.content
        
        try {
            const apiResponseObject = JSON.parse(apiMessage);
            // Access the questions array
            const apiQuestionsArray = apiResponseObject.questions;
            const updatedQuestions = [...currentQuestions]; // Create a copy of currentQuestions
    
            apiQuestionsArray.forEach(apiQuestion => {
              // Add the required flags
              apiQuestion.author_id = 25;
              apiQuestion.pendingSubmit = true;
              // Parse qnumber from string to number
              const apiQuestionNumber = parseInt(apiQuestion.qnumber, 10);
              // Find and update the existing question or add a new one
              const existingQuestionIndex = updatedQuestions.findIndex(q => q.qnumber === apiQuestionNumber);
              if (existingQuestionIndex !== -1) {
                  // Merge all properties except qnumber
                  updatedQuestions[existingQuestionIndex] = { 
                      ...updatedQuestions[existingQuestionIndex], 
                      ...apiQuestion,
                      qnumber: updatedQuestions[existingQuestionIndex].qnumber // Keep the original numeric qnumber
                  };
              } else {
                  // For new questions, set qnumber as a number
                  apiQuestion.qnumber = apiQuestionNumber;
                  updatedQuestions.push(apiQuestion);
              }
            });
        } catch (error) {
            console.log("MALFORMED OUTPUT CANNOT BE PARSED", apiMessage, error.message);
            dispatch(addSystemMessage("Error parsing JSON, please check your output and try again"))
            // Handle or log the error appropriately
        }
    
        if (apiMessage){
        dispatch(appendMessage(apiMessage));
        dispatch(addSystemMessage("succefully fixed json"));
        } else {
            dispatch(addSystemMessage("Error producing a repsonse."));
            dispatch(appendMessage({
                role: 'assistant',
                content: 'Error producing a repsonse.',
            }));
        }
    }
)

export const uploadVQuestions = createAsyncThunk('imagify/uploadVQuestions', async (
    updatedPinkQuestions , {dispatch, getState} ) => {

        const paperId = getState().imagifySlice.selectedPaper.id;
        const papername = getState().imagifySlice.selectedPaper.file_name;
        dispatch(addSystemMessage(`attaching the vision quesiton to paper ${papername}`))
    const { data, error } = await supabase.from('questions').upsert(updatedPinkQuestions, { onConflict: 'unique_key' });
    if (error) {
        console.log(error.message);
        throw error;
    }
    dispatch(fetchImagifyQuestions(paperId));
    dispatch(addSystemMessage(`succefully attached the vision quesiton to paper ${papername}`))
    return data;
});

// Async thunk for handling the conversion and state update
export const convertPdfToImageAndStore = createAsyncThunk(
    'imagify/convertPdfToImageAndStore',
    async (_, { dispatch, getState }) => {
        const images = getState().imagifySlice.selectedImages
        const questions = getState().imagifySlice.selectedQuestions
        const currentQuestions = getState().imagifySlice.imagifyQuetions;
       
        const imageUrls = images.map(image => image.public_url);
        const questionNumbers = questions.map(question => question.qnumber);
        
        const prompt = getPrompt(questionNumbers);        
       
     dispatch(addSystemMessage("gathering images and quesitons and preparing prompt"))
        const content = [
            { type: "text", text: prompt },
            ...imageUrls.map(url => ({
                type: "image_url",
                image_url: {
                    url, // Ensure this key is 'image_url', not just 'url'
                    detail: 'low' 
                }
            }))
        ];
    
        // Construct the message object
        const message = {
            role: "user",
            content: content
        }

        const systemMessage = {
            role: "system",
            content: [{ type: "text", text: systemPrompt }]
        };
        
        // Add the system message to the
        

    // Log for debugging
    console.log("convertPdfToImageAndStore", message);
  
       dispatch(appendMessage(message));
       dispatch(addSystemMessage("waiting for openai reponse"));
        console.log("convertPdfToImageAndStore", message)
        const output = await supabase.functions.invoke('pdfSplitter', {body: { messages: 
            [
                systemMessage,
                message
            ] 
        } })
        
        const jsonPattern = /\[.*\]/s;
        const apiResponseArray1 = output?.data?.choices[0]?.message?.content?.match(jsonPattern);
        const assistantMessage = output?.data?.choices[0]?.message

       
        console.log("Assistant Message", assistantMessage)
        if(assistantMessage){
            dispatch(appendMessage(assistantMessage));
        } else {
            dispatch(appendMessage({role: "system", content: [{ type: "text", text: "No response from openai, please check your input and try again" }]}) );
        }
       
        dispatch(addSystemMessage("adding response to message log"));
        let apiResponseArray;
try {
    apiResponseArray = JSON.parse(apiResponseArray1);
    console.log("JSON SPLITTER CALL OUTPUT", apiResponseArray);
} catch (error) {
    console.log("MALFORMED OUTPUT CANNOT BE PARSED", apiResponseArray1);
    console.error("Error parsing JSON:", error);
    dispatch(addSystemMessage("Error parsing JSON, please check your output and try again"))
    // Handle or log the error appropriately
}
      // Merge API response into current questions
      const updatedQuestions = [...currentQuestions]; // Create a copy of currentQuestions

      apiResponseArray.forEach(apiQuestion => {
        // Add the required flags
        apiQuestion.author_id = 25;
        apiQuestion.pendingSubmit = true;
    
        // Parse qnumber from string to number
        const apiQuestionNumber = parseInt(apiQuestion.qnumber, 10);
    
        // Find and update the existing question or add a new one
        const existingQuestionIndex = updatedQuestions.findIndex(q => q.qnumber === apiQuestionNumber);
        if (existingQuestionIndex !== -1) {
            // Merge all properties except qnumber
            updatedQuestions[existingQuestionIndex] = { 
                ...updatedQuestions[existingQuestionIndex], 
                ...apiQuestion,
                qnumber: updatedQuestions[existingQuestionIndex].qnumber // Keep the original numeric qnumber
            };
        } else {
            // For new questions, set qnumber as a number
            apiQuestion.qnumber = apiQuestionNumber;
            updatedQuestions.push(apiQuestion);
        }
    });
    
        // Update the questions array in state
       console.log("MERGED QUESTIONS", updatedQuestions)
       dispatch(addSystemMessage(`succefully processed questions ${questionNumbers.join(",")}`))
      return updatedQuestions;
    }
  );
  

export const fetchPastPapers = createAsyncThunk('pastPapers/fetchPastPapers', async () => {
    const { data, error } = await supabase.from('past_papers').select('*');
    if (error) {
        console.log(error.message);
        throw error;
    }
    return data;
}
);

export const fetchPaperImages = createAsyncThunk('paperImages/fetchPaperImages', async (
    paperId
) => {
    const { data, error } = await supabase.from('past_paper_images').select('*').eq('past_paper_id', paperId);
    if (error) {
        console.log(error.message);
        throw error;
    }
    console.log("THUNK PAPER IMGAGE", paperId, data)
    return data;
});


export const fetchImagifyQuestions = createAsyncThunk('imagifyQuestions/fetchImagifyQuestions', async (
    paperId 
) => {
    const { data, error } = await supabase.from('questions').select('*').eq('raw_paper', paperId);
    if (error) {
        console.log(error.message);
        throw error;
    }
    return data;
});

export const updateSelectedQuestion = createAsyncThunk('imagifyQuestions/updateSelectedQuestion', async (
    { questionId, question }
) => {
    const { data, error } = await supabase.from('questions').update({ question }).eq('id', questionId);
    if (error) {
        console.log(error.message);
        throw error;
    }
    return data;
});



// export const fetchAIQuestions = createAsyncThunk('aIQuestions/fetchAIQuestions', async () => {
//     const { data, error } = await supabase.from('aIQuestions').select('*').single();
//     if (error) {
//         throw error;
//     }
//     return data;
// });

const imagifySlice = createSlice({
    name: 'imagify',
    initialState,
    reducers: {
    //    toggleGroupImage: (state, action) => {
    //         const { image } = action.payload;
    //         const index = state.selectedGroupImages.findIndex((item) => item.id === image.id);
    //         if (index === -1) {
    //             state.selectedGroupImages.push(image);
    //         } else {
    //             state.selectedGroupImages.splice(index, 1);
    //         }
    //    },
    addSelectedImage: (state, action) => {
        const { image } = action.payload;
        const index = state.selectedImages.findIndex((item) => item.id === image.id);
        if (index === -1) {
            state.selectedImages.push(image);
        } else {
            state.selectedImages.splice(index, 1);
        }
    },
    addSelectedQuestion: (state, action) => {
        const { question } = action.payload;
        const index = state.selectedQuestions.findIndex((item) => item.qnumber === question.qnumber);
        if (index === -1) {
            state.selectedQuestions.push(question);
        } else 
        {
            state.selectedQuestions.splice(index, 1);
        }
    },
    justaddQuestion: (state, action) => {
        const { question } = action.payload;
        const index = state.selectedQuestions.findIndex((item) => item?.qnumber === question?.qnumber);
        if (index === -1) {
            state.selectedQuestions.push(question);
        } 
    },
    justaddImage: (state, action) => {
        const { image } = action.payload;
        const index = state.selectedImages.findIndex((item) => item.id === image.id);
        if (index === -1) {
            state.selectedImages.push(image);
        }
    },
         setSelectedPaper: (state, action) => {
              const { paper } = action.payload;
              state.selectedPaper = paper;
         },
            setSelectedImagifyQuestion: (state, action) => {
                const { question } = action.payload;
                state.selectedImagifyQuestion = question;
            },
            setSelectedPaperImage: (state, action) => {
                const { image } = action.payload;
                state.selectedPaperImage = image;
            },
            updateImagifyQuestions: (state, action) => {
                state.imagifyQuetions = action.payload;
            },
            appendMessage: (state, action) => {
                state.messages.push(action.payload);
            },
            clearMessages: (state) => {
                state.messages = [];
            },   
            
        toggleDialog: (state) => {
            state.openDialog = !state.openDialog;
        },
        addSystemMessage: (state, action) => {
            state.systemMessage = action.payload;
        },
        clearSystemMessage: (state) => {
            state.systemMessage = "";
        }
    },
   extraReducers: {
        [fetchPastPapers.pending]: (state) => {
            state.loading = true;
        },
        [fetchPastPapers.fulfilled]: (state, action) => {
            state.loading = false;
            state.pastPapers = action.payload;
        },
        [fetchPastPapers.rejected]: (state) => {
            state.loading = false;
        },
        [fetchImagifyQuestions.pending]: (state) => {
            state.loading = true;
        },
        [fetchImagifyQuestions.fulfilled]: (state, action) => {
            state.loading = false;
            state.imagifyQuetions = action.payload;
        },
        [fetchImagifyQuestions.rejected]: (state) => {
            state.loading = false;
        },
        [fetchPaperImages.pending]: (state) => {
            state.loading = true;
        },
        [fetchPaperImages.fulfilled]: (state, action) => {
            state.loading = false;
            state.paperImages = action.payload;
        },
        [fetchPaperImages.rejected]: (state) => {
            state.loading = false;
        },
        [convertPdfToImageAndStore.pending]: (state) => {
            state.loading = true;
        },
        [convertPdfToImageAndStore.fulfilled]: (state, action) => {
            state.loading = false;
            state.imagifyQuetions = action.payload;
        },
        [convertPdfToImageAndStore.rejected]: (state) => {
            state.loading = false;
        },
        [uploadVQuestions.pending]: (state) => {
            state.loading = true;
        },
        [uploadVQuestions.fulfilled]: (state) => {
            state.loading = false;
        },
        [uploadVQuestions.rejected]: (state) => {
            state.loading = false;
        },
        [fixJson.pending]: (state) => {
            state.loading = true;
        },
        [fixJson.fulfilled]: (state) => {
            state.loading = false;
        },
        [fixJson.rejected]: (state) => {
            state.loading = false;
        },
   }
});

const selectPastPapers = (state) => state.imagify.pastPapers;
const selectSelectedPaper = (state) => state.imagify.selectedPaper;
const selectImagifyQuestions = (state) => state.imagify.imagifyQuetions;
const selectSelectedImagifyQuestion = (state) => state.imagify.selectedImagifyQuestion;

export const { toggleGroupImage, setSelectedPaper, setSelectedImagifyQuestion, setSelectedPaperImage,
    addSelectedImage, addSelectedQuestion, justaddQuestion, justaddImage, updateImagifyQuestions,
    appendMessage, clearMessages, toggleDialog, addSystemMessage, clearSystemMessage
} = imagifySlice.actions;

export { selectPastPapers, selectSelectedPaper, selectImagifyQuestions, selectSelectedImagifyQuestion };

export default imagifySlice.reducer;
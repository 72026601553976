import React, { useState, useEffect } from 'react';
import withLayout from '../../../hooks/withLayout';
import Dashboard from './Dashboard';
import ImagesGrid from './ImagesGrid';
import LeftDrawer from './LeftDrawer';
import { 
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import Preview from './Preview';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material';

//Redux 
import { useDispatch, useSelector } from 'react-redux';
import { fetchPastPapers, 
  fetchImagifyQuestions, 
  fetchPaperImages, 
  selectSelectedPaper,
  setSelectedPaperImage, 
  setSelectedPaper, 
  convertPdfToImageAndStore,
  setSelectedImagifyQuestion,
  addSelectedImage,
  addSelectedQuestion,
  justaddQuestion,
  justaddImage,
  toggleDialog,
} from '../../../state/imagify/imagifySlice';
import Chat from './Chat.js';



const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    min-width: 250px; // Set your desired minimum width
  }
`;

function Imagify() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const selectedPaper = useSelector((state) => state.imagifySlice.selectedPaper);
  const pastPapers = useSelector((state) => state.imagifySlice.pastPapers);
  const paperImages = useSelector((state) => state.imagifySlice.paperImages);
  const selectedPaperImage = useSelector((state) => state.imagifySlice.selectedPaperImage);
  const selectedQuestion = useSelector((state) => state.imagifySlice.selectedImagifyQuestion);
const questions = useSelector((state) => state.imagifySlice.imagifyQuetions);
const selectedQuestions = useSelector((state) => state.imagifySlice.selectedQuestions);
const selectedImages = useSelector((state) => state.imagifySlice.selectedImages);
const messages = useSelector((state) => state.imagifySlice.messages);
const openDialog = useSelector((state) => state.imagifySlice.openDialog);

// console.log("QUESITONS ARRAY NOW", questions)

    const dispatch = useDispatch();

    const setPaper = (paper) => {
        dispatch(setSelectedPaper({ paper }))
    }

    const justaddGroupImage = (image) => {
        dispatch(justaddImage({ image }))
    }

    const justaddGroupQuestion = (question) => {
        dispatch(justaddQuestion({ question }))
    }

    const addGroupImage = (image) => {
        dispatch(addSelectedImage({ image }))
    }

    const addGroupQuestion = (question) => {
        dispatch(addSelectedQuestion({ question }))
    }

    const setQuestion = (question) => {
        dispatch(setSelectedImagifyQuestion({ question }))
    }

    const setImage = (image) => {
        dispatch(setSelectedPaperImage({ image }))
    }


    // console.log("SELECTED PAPER", selectedPaper, )
    // console.log("CONVERTED PAST PAPERS", paperImages)

    const fetch = async () => {
        await dispatch(fetchPastPapers())
    }

  useEffect(() => {
    fetch()
    }, [])

    useEffect(() => {
        if (selectedPaper) {
            dispatch(fetchImagifyQuestions(selectedPaper.id))
            dispatch(fetchPaperImages(selectedPaper.id))
        }
    }, [selectedPaper])

    const convertPdfToImages = async (PdfFile, file_name) => {
        await dispatch(convertPdfToImageAndStore({ PdfFile, file_name }))
    }


  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {/* Dashboard with min height and border */}
      <div className="border-b border-gray-300 min-h-[60px] overflow-auto flex-shrink-0">
        <Dashboard 
          toggleDrawer={toggleDrawer} 
          selectedPaper={selectedPaper} 
          selectedQuestion={selectedQuestion}
          questions={questions}
          convertPdfToImages={convertPdfToImages}
          setQuestion={setQuestion}
          setImage={setImage}
          paperImages={paperImages}
          selectedPaperImage={selectedPaperImage}
          selectedImages={selectedImages}
          selectedQuestions={selectedQuestions}
          addSelectedImage={addGroupImage}
          addSelectedQuestion={addGroupQuestion}
          justaddQuestion={justaddGroupQuestion}
          justaddImage={justaddGroupImage}
          messages={messages}
        />
      </div>

      <StyledDrawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        className="z-30"
      >
        <LeftDrawer pastPapers={pastPapers} setPaper={setPaper} toggleDrawer={toggleDrawer} />
      </StyledDrawer>

      {/* Main content area */}
      <div className="flex-grow flex flex-col md:flex-row p-4 gap-4">
        {/* Preview with min height, border, and shadow */}
        <div className="flex-1 bg-white border border-gray-300 shadow-lg min-h-0 p-2">
          <Preview 
          selectedQuestion={selectedQuestion}
          />
        </div>

        {/* Images Grid with flexible basis and border */}
       
        <div className="flex-1">
    <ImagesGrid 
      paperImages={paperImages}
      selectedPaperImage={selectedPaperImage}
      setImage={setImage}
    />
  </div>
        
      </div>
      <Dialog
        open={openDialog}
        onClose={() => dispatch(toggleDialog())}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Chat 
          messages={messages}
          />
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => dispatch(toggleDialog())}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withLayout({ layout: 'default' })(Imagify);

// snackbarSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

// Async thunk for fetching questions from an API
export const fetchLeaderBoard = createAsyncThunk(
    'leaderboard/fetchLeaderBoard',
    async () => {
        const { data, error } = await supabase
            .from('employee_leader_board')
            .select('*')
            .order('number_of_questions', { ascending: false })
            .limit(10);
        if (error) {
            console.log(error)
            throw error;
        }
        return data;
    }
);


// Initial state
const initialState = {
messeages: [],
isFullScreen: false,
LeaderBoard: [],
error: null,
status: 'idle',

};

// Slice
const snackbarSlice = createSlice({
  name: 'Snackbar',
  initialState,
    reducers: {
        // Your regular synchronous reducers go here
        addMessage: (state, action) => {
            state.messeages.push(action.payload)
        },
        removeMessage: (state, action) => {
            state.messeages = state.messeages.filter((messeage) => messeage !== action.payload)
        },
        clearMessages: (state) => {
            state.messeages = []
        },
        toggleFullScreen: (state, action) => {
            console.log("TOGGLE TOGGLE")
            state.isFullScreen = !state.isFullScreen
        }
    },
    extraReducers: (builder) => {
        // Your custom reducers go here
        builder.addCase(fetchLeaderBoard.fulfilled, (state, action) => {
            state.LeaderBoard = action.payload;
            state.status = 'succeeded';
        })
        .addCase(fetchLeaderBoard.rejected, (state, action) => {
            state.error = action.error.message;
            state.status = 'failed';
        })
        .addCase(fetchLeaderBoard.pending, (state, action) => {
            state.status = 'loading';
        })
    },

});

// Export the async thunk (optional: if you want to use it outside of this file)
// export { fetchQuestions };

// Export the actions created by the slice (if any)
export const {
    addMessage,
    removeMessage,
    clearMessages,
    toggleFullScreen
} = snackbarSlice.actions;

// Export the reducer
export default snackbarSlice.reducer;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

//rtk
import { useDispatch, useSelector } from 'react-redux';
import { fetchGptModel } from '../../../state/jobs/openAiSlice';
import { uploadAvatarAndAddAuthor } from '../../../state/user';

//layout
import withLayout from '../../../hooks/withLayout';

//openAI
import openai from '../../../services/openai';

function SignUpAuthor() {
    const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [avatarType, setAvatarType] = useState(null); // 'upload' or 'ai'
  const [avatarPreview, setAvatarPreview] = useState(null);
  const openAiDescription = useSelector(state => state.openAi.gptResponse)
  const errors = useSelector(state => state.user.error)
  const loadingAi = useSelector(state => state.openAi.loading)
  const [showForm, setShowForm] = useState(false);
  const authorName = useSelector(state => state.user.authorName)
  const authorAvatar = useSelector(state => state.user.authorAvatar)



  const formik = useFormik({
    initialValues: {
      fullName: '',
      avatar: null,
      description: '',
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required('Full name is required'),
      avatar: Yup.mixed().required(

        'Either upload or click generate image'
      ),
    }),
    onSubmit: (values) => {
      setLoading(true);
      // Here, you'd typically dispatch your thunk, e.g., `dispatch(signupAuthor(values))`
      setTimeout(() => setLoading(false), 2000); // Mock async operation
      console.log({values})
        dispatch(uploadAvatarAndAddAuthor(values))
    },
  });


   

    // Mock function to get description from OpenAI
    const generateDescription = async () => {
        // Mocking the OpenAI call
        // const description = `This is a mock description for ${formik.values.fullName}.`;
       const prompt = `You a description generator for social game, user has chosen the name below, 
       make a funny short 1 sentence description for the character named:
        ${formik.values.fullName}.`;
        dispatch(fetchGptModel({fullPrompt: prompt, model: 'gpt-3.5-turbo'}))
        // formik.setFieldValue('description', description);
        
      };

      const handleAvatarChange = (event) => {
        const file = event.currentTarget.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const avatarDataURL = reader.result;
                setAvatarPreview(avatarDataURL);
                formik.setFieldValue('avatar', avatarDataURL);  // Update Formik's state here
            };
            reader.readAsDataURL(file);
        }
    };
    
    const generateImage = async () => {
        const avatarUrl = `https://robohash.org/${formik.values.description.replace(/\s+/g, '-')}.png`;
        setAvatarPreview(avatarUrl);
    
        // Fetch the image from the URL
        const response = await fetch(avatarUrl);
        const imageBlob = await response.blob();
    
        // Convert the image Blob into a data URL
        const reader = new FileReader();
        reader.onloadend = () => {
            const avatarDataURL = reader.result;
            formik.setFieldValue('avatar', avatarDataURL); // Update Formik's state with the data URL
        };
        reader.readAsDataURL(imageBlob);
    };
    
    useEffect(() => {
        if(authorName && authorAvatar){
            setShowForm(false)
            formik.setFieldValue('fullName', authorName)
            formik.setFieldValue('avatar', authorAvatar)
        }
    }, [authorName, authorAvatar ])
    
    

    useEffect(() => {
        if(openAiDescription){
            formik.setFieldValue('description', openAiDescription)
        }
    }, [openAiDescription])

    const disabled = !formik.values.description

    if(!showForm){

        return (
            <div className="bg-gray-100 min-h-screen flex items-center justify-center">
              <div className="bg-white p-8 rounded-lg shadow-md w-96">
              <h2 className="text-2xl mb-6">
  you already have an author account <strong>{authorName}</strong> but you can edit it, just click below
</h2>

                <div className="mt-6">
                    <img src={authorAvatar} id='avatar' name='avatar' alt="Preview" className="w-32 h-32 rounded-full" />
                    <button
                      type="button"
                      className="w-full p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none"
                      onClick={() => setShowForm(true)}
                    >
                      {authorName && authorAvatar ? 'Edit my info' : 'Sign Up'}
                    </button>
                  </div>
              </div>
            </div>
          );
    }


  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        {errors && (
            <div className="text-red-500 text-xs mt-2">{errors}</div>
        )
        }
        <h2 className="text-2xl mb-6">Sign up as an Author</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label htmlFor="fullName" className="block text-sm font-medium text-gray-600">
              Full Name
            </label>
            <input
              id="fullName"
              name="fullName"
              type="text"
              className="mt-1 p-2 w-full border rounded-md"
              onChange={formik.handleChange}
              value={formik.values.fullName}
            />
           {formik.errors.avatar && formik.touched.avatar && (
    <div className="text-red-500 text-xs mt-2">{formik.errors.avatar}</div>
)}

          </div>

          {
            loadingAi ? <div className="text-red-500  mt-2"><h1>Writting a description for someone lazy</h1></div> : null
          }

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">Avatar</label>
            <div className="mt-2">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio"
                  name="avatarType"
                  value="upload"
                  onChange={(e) => setAvatarType(e.target.value)}
                />
                <span className="ml-2">Upload</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input
                  type="radio"
                  className="form-radio"
                  name="avatarType"
                  value="ai"
                  onChange={(e) => setAvatarType(e.target.value)}
                />
                <span className="ml-2">AI Generated</span>
              </label>
            </div>

            {avatarType === 'upload' && (
              <div className="mt-2">
                <input
                  type="file"
                  name="avatar"
                  onChange={handleAvatarChange}
                />
              </div>
            )}

{avatarType === 'ai' && (
      <div className="mt-2">
        <label htmlFor="description" className="block text-sm font-medium text-gray-600">
          Describe Yourself
        </label>
        <div className="flex items-start space-x-2">
          <button
            type="button"
            onClick={generateDescription}
            className="p-1 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Generate Description
          </button>
   
          <button
  type="button"
  onClick={generateImage}
  disabled={disabled}
  className={`p-1 ${disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700'} text-white rounded-md`}
>
  Generate Image
</button>


        </div>
        <textarea
                  id="description"
                  name="description"
                  rows="4"
                  className="mt-1 p-2 w-full border rounded-md"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                ></textarea>
      </div>
    )}
          </div>

          {avatarPreview && (
            <div className="mb-4">
              <img src={avatarPreview} id='avatar' name='avatar' alt="Preview" className="w-32 h-32 rounded-full" />
            </div>
          )}

          <div className="mt-6">
            <button
              type="submit"
              className="w-full p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Sign Up'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default withLayout({ layout: 'default' })(SignUpAuthor);

import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import { setCapturedImg } from './state/processQuestions';
import { useDispatch } from 'react-redux';

function ScreenCapture({ children }) {
    const dispatch = useDispatch();
  const [startPos, setStartPos] = useState(null);
  const [endPos, setEndPos] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [shiftPressed, setShiftPressed] = useState(false);
  

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Shift') {
        setShiftPressed(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Shift') {
        setShiftPressed(false);
        setIsDragging(false);
        setStartPos(null);
        setEndPos(null);
      }
    };

    //     document.addEventListener('selectionchange', () => {
    //   const selectedText = window.getSelection().toString();
    //   console.log('Selected text:', selectedText);
    // });

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const onMouseDown = (e) => {
    if (shiftPressed) {
      document.body.classList.add('no-select');
      setIsDragging(true);
      setStartPos({ x: e.clientX, y: e.clientY });
    }
  };

  const onMouseMove = (e) => {
    if (!isDragging || !shiftPressed) return;
    setEndPos({ x: e.clientX, y: e.clientY });
  };

  const onMouseUp = () => {
    if (startPos && endPos) {
      captureArea(startPos, endPos);
    }
    setIsDragging(false);
    document.body.classList.remove('no-select');
    setStartPos(null);
    setEndPos(null);
  };

  const captureArea = async (start, end) => {
    const x = Math.min(start.x, end.x);
    const y = Math.min(start.y, end.y);
    const width = Math.abs(start.x - end.x);
    const height = Math.abs(start.y - end.y);

    const canvas = await html2canvas(document.body, {
      x,
      y,
      width,
      height,
    });

    const capturedImageUrl = canvas.toDataURL();
         // Dispatch the image URL to Redux state
     dispatch(setCapturedImg(capturedImageUrl));

    const img = new Image();
    img.src = capturedImageUrl;

    img.onload = () => {
        // create canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        // convert to blob and write to clipboard
        canvas.toBlob(blob => {
            const data = [new ClipboardItem({ 'image/png': blob })];
            navigator.clipboard.write(data).catch(console.error);
        });
    };
  };

  const style = startPos && endPos ? {
    left: Math.min(startPos.x, endPos.x),
    top: Math.min(startPos.y, endPos.y),
    width: Math.abs(startPos.x - endPos.x),
    height: Math.abs(startPos.y - endPos.y),
    zIndex: 1000,
  } : {};

  return (
    <div
      className="h-screen relative"
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      {shiftPressed && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-10 z-50 no-select no-click" />
      )}
      {isDragging && (
        <div
          className="absolute border-2 border-dashed border-red-500"
          style={style}
        />
      )}
      {children}
      {capturedImage && (
        <div className="mt-4 p-4">
          <img src={capturedImage} alt="Captured content" />
        </div>
      )}
    </div>
  );
}

export default ScreenCapture;

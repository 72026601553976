import React from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

function ImagesGrid({ setImage, selectedPaperImage, paperImages }) {
  const navigate = (direction) => {
    const currentIndex = paperImages.findIndex(image => image.id === selectedPaperImage.id);
    const newIndex = direction === 'left' ? Math.max(0, currentIndex - 1) : Math.min(paperImages.length - 1, currentIndex + 1);
    setImage(paperImages[newIndex]);
  };

  console.log({selectedPaperImage})

  return (
    <div className="flex flex-col items-center justify-center my-4">
      <div className="flex items-center justify-center mb-4">
        <Button variant="outlined" onClick={() => navigate('left')} disabled={!selectedPaperImage || paperImages.indexOf(selectedPaperImage) === 0}>
          <ArrowBackIos />
        </Button>

        
          {selectedPaperImage ? (
            <img src={selectedPaperImage.public_url} alt={`Page ${selectedPaperImage.page_number}`} className="w-auto h-100" />
          ) : (
            <Typography variant="subtitle1" className="p-4">No Image Selected</Typography>
          )}
      

        <Button variant="outlined" onClick={() => navigate('right')} disabled={!selectedPaperImage || paperImages.indexOf(selectedPaperImage) === paperImages.length - 1}>
          <ArrowForwardIos />
        </Button>
      </div>

      {selectedPaperImage && (
        <Typography variant="subtitle1">
          Page {selectedPaperImage.page_number}
        </Typography>
      )}
    </div>
  );
}

export default ImagesGrid;

import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, CircularProgress } from '@mui/material';
import withLayout from '../hooks/withLayout';
import { useSelector, useDispatch } from 'react-redux';
import { signIn } from '../state/user';

function Login() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const loading = user.loading;
  const error = user.error;

  const { control, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = data => {
    // console.log(data, user);
    dispatch(signIn(data));
  };

  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px', // Adjust as needed
      width: '100%',
      maxWidth: '400px',
      backgroundColor: '#fff', // Adjust as needed
      borderRadius: '4px', // Adjust as needed
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)', // Adjust as needed
    },
    input: {
      marginBottom: '16px', // Adjust as needed
    },
    button: {
      marginTop: '16px', // Adjust as needed
    },
  };

  return (
    <div style={styles.root}>
      <form style={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <h1>learn labs UK 🧪</h1>
        {error && <p>{error}</p>}
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              style={styles.input}
              label="Email"
              variant="outlined"
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: 'Password is required',
            minLength: {
              value: 6,
              message: 'Password must have at least 6 characters',
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              style={styles.input}
              label="Password"
              type="password"
              variant="outlined"
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
            />
          )}
        />
        <Button
          style={styles.button}
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
        >
          {loading && <CircularProgress size={24} />}
          {loading ? 'Loading' : 'Login'}
        </Button>
      </form>
    </div>
  );
}

export default withLayout({ layout: 'none' })(Login);

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLeaderBoard } from '../../../state/system/SnackBar';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Avatar, Typography } from '@mui/material';
import withLayout from '../../../hooks/withLayout';
import dayjs from 'dayjs';

function LeaderBoard() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.SnackBar.status === 'loading');
  const LeaderBoardData = useSelector((state) => state.SnackBar.LeaderBoard);

  React.useEffect(() => {
    dispatch(fetchLeaderBoard());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="mb-4 text-lg font-semibold">Fetching the leaderboard</p>
        <CircularProgress />
      </div>
    );
  }

  console.log(LeaderBoardData);

  return (
    <div className="p-6">
      <Typography variant="h4" gutterBottom className="mb-4">
        Leaderboard
      </Typography>
      <Table className="min-w-full divide-y divide-gray-200">
        <TableHead className="bg-gray-50">
          <TableRow>
            <TableCell>Avatar</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Questions</TableCell>
            <TableCell>Joined</TableCell>
            <TableCell>Last Submit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...LeaderBoardData]
            ?.sort((a, b) => b.number_of_questions - a.number_of_questions)
            ?.map((user, index) => (
              <TableRow key={user.id} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                <TableCell>
                  <Avatar src={user.avatar_url} alt={user.username} />
                </TableCell>
                <TableCell>
                  <Typography variant="body1" className="font-medium">
                    {user.username}
                  </Typography>
                </TableCell>
                <TableCell>{user.number_of_questions}</TableCell>
                <TableCell>{dayjs(user.created_at).format('MMMM D, YYYY')}</TableCell>
                <TableCell>{dayjs(user.updated_at).format('MMMM D, YYYY, h:mm A')}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default withLayout({ layout: 'default' })(LeaderBoard);

import React, { useState } from "react";
import { Drawer, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import LessonSquare from "./lessonJobSquare";
import { fetchAllJobLessons } from "../../../../state/jobs/lessonJob";
import { setCurrentPage, clearErrors, selectJob } from "../../../../state/jobs/lessonJob";

function JobDrawer({ isOpen, onClose }) {
  const jobs = useSelector((state) => state.lessonJob.jobs); // Adjust based on your redux store structure
  const currentIteration = useSelector(
    (state) => state.iterations.currentIteration
  ); // Adjust based on your redux store structure
  const [lessonIdFilter, setLessonIdFilter] = useState(null);
  const totalPages = useSelector((state) => state.lessonJob.totalPages);
  const currentPage = useSelector((state) => state.lessonJob.currentPage);
  const error = useSelector((state) => state.lessonJob.error);
  const currentJobId = useSelector((state) => state.lessonJob.currentlySelectedJob?.id);



  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(clearErrors());
    }, [dispatch]);

  const handleSquareClick = (job) => {
    console.log("JOB")
   dispatch(selectJob(job))
  };

  return (
    <div>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={onClose}
        PaperProps={{ className: "w-64 bg-gray-100" }} // Tailwind styling applied
      >
        <div className="p-4">
          <h1 className="text-xl mb-4">Job Drawer</h1>

          {/* Filter */}
          <div className="flex flex-wrap justify-between mb-4">
            <input
              value={lessonIdFilter || ""}
              onChange={(e) => setLessonIdFilter(e.target.value)}
              placeholder="Filter by Lesson ID"
              className="mr-2" // some margin to separate input and button
            />
            <Button
              onClick={() =>
                dispatch(
                  fetchAllJobLessons({ page: 1, lesson_id: lessonIdFilter })
                )
              }
            >
              Filter
            </Button>
            <Button
              onClick={() => {
                setLessonIdFilter(null);
                dispatch(clearErrors())
                dispatch(fetchAllJobLessons({ page: 1 }));
              }}
            >
              Reset Filters
            </Button>
          </div>

          {/* Pagination */}
          <div className="flex justify-between">
            <Button
              disabled={currentPage === 1}
              onClick={() => {
                const newPage = currentPage - 1;
                setCurrentPage(newPage);
                dispatch(
                  fetchAllJobLessons({
                    page: newPage,
                    lesson_id: lessonIdFilter,
                  })
                );
              }}
            >
              Previous
            </Button>
            <Button 
    disabled={currentPage === totalPages} 
    onClick={() => {
        const newPage = currentPage + 1;
        setCurrentPage(newPage);
        dispatch(fetchAllJobLessons({ page: newPage, lesson_id: lessonIdFilter }));
    }}
>
    Next
</Button>
          </div>
          <div>
            <p className="text-center text-sm my-auto">
              Page {currentPage} of {totalPages}
            </p>
          </div>
{
    error && <p className="text-red-500 text-center">{error.message || error}</p>
}
          <div className="grid grid-cols-5 gap-2">
            {jobs.map((job) => (
              <LessonSquare
                key={job.id}
                job={job}
                currentIteration={currentIteration}
                onClick={() => handleSquareClick(job)}
                currentJobId={currentJobId}
              />
            ))}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default JobDrawer;

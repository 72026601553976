import React from 'react'
import PaperPreview from './PaperPreview'
import InputPreview from './InputPreview'

function Preview({categories}) {
return (
    <div className="flex h-full">
        <div className="w-1/2 h-full">
            <InputPreview 
            categories={categories}
            />
        </div>
        <div className="w-1/2 h-full">
            <PaperPreview />
        </div>
    </div>
);
}

export default Preview
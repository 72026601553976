import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Toolbar, Typography, Button, Collapse, Dialog, DialogContent, Menu, MenuItem, Icon } from '@mui/material';
import IterationsModal from './IterationsModal'; // Make sure the import is capitalized
import { fetchAllIterations, createNewIteration } from '../../../../state/jobs/iterationsSlice';
import { populateJobLessons } from '../../../../state/jobs/lessonJob';
import { nextSelectedJob, previousSelectedJob } from '../../../../state/jobs/lessonJob';
import { DeveloperBoardOutlined, KeyboardVoiceOutlined } from '@mui/icons-material';
import { fetchVoices } from '../../../../state/jobs/voiceOverSlice';
import { setSelectedVoice } from '../../../../state/jobs/voiceOverSlice';

function Dashboard({ onOpenDrawer, selectedJob, setModel, model, gptModels }) {
  const dispatch = useDispatch();
  const iterations = useSelector(state => state.iterations); // Adjust based on your redux store structure
  const vAi = useSelector(state => state.voiceOverSlice.selectedVoice)
  const voices = useSelector(state => state.voiceOverSlice.voices)

  const currentIteration = iterations.currentIteration // Assuming the latest iteration is at the end

console.log({vAi})

  React.useEffect(() => {
    dispatch(fetchAllIterations());
    dispatch(fetchVoices());
    }, [dispatch]);

    React.useEffect(() => {
      if(voices){
        dispatch(setSelectedVoice(voices[0]))
      }
    }, [voices, dispatch]);
   
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIDialogOpen, setIsIDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleVoiceMenuOpen = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleVoiceMenuClose = () => {
  setAnchorE2(null);
  };
  

  const handleGenerateLessonJob = () => {
    // Dispatch actions to generate lesson job and create a new iteration
    dispatch(populateJobLessons());
    // dispatch(createNewIteration()); // Pass any required args here
  };

  return (
    <div className="flex flex-col w-full" >
      <AppBar position="static" className="bg-white text-gray-900 shadow-md">
        <Toolbar className="justify-between">
          <Button color="inherit" onClick={onOpenDrawer}>Open Drawer</Button>
          <Button
            variant="contained"
            color="primary"
            className="bg-blue-600 text-white rounded shadow-sm"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? "Expand Dashboard" : "Collapse Dashboard"}
          </Button>
          <Typography variant="h6" className="text-center flex-grow">
            Dashboard
          </Typography>
          <div>
          <Button
              variant="text"
              color="inherit"
              onClick={() => setIsIDialogOpen(true)}
            >
              Iteration: {currentIteration?.id} {/* Replace with the appropriate field if different */}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="bg-blue-600 text-white rounded shadow-sm"
              onClick={handleGenerateLessonJob}
            >
              Generate Lesson Job
            </Button>
          </div>
        </Toolbar>
      </AppBar>
<Collapse in={!isCollapsed}>
    <div className="p-4 flex justify-between">
        <div>
        </div>
        <Button variant='contained' onClick={handleVoiceMenuOpen}>
          <KeyboardVoiceOutlined />
            {vAi?.name || "loading"}
        </Button>
        <Menu anchorEl={anchorE2} open={Boolean(anchorE2)} onClose={handleVoiceMenuClose}>
          {voices.map((voice) => (
                <MenuItem key={voice.voice_id} onClick={() => {setSelectedVoice(voice); handleVoiceMenuClose()}}>{voice.name}</MenuItem>
            ))}
          </Menu>
          <Button variant='contained' onClick={handleMenuOpen}>
            <DeveloperBoardOutlined />
            {model}
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            {gptModels.map((model) => (
                <MenuItem key={model} onClick={() => {setModel(model); handleMenuClose()}}>{model}</MenuItem>
            ))}
          </Menu>
        <div>
            <Button disabled={!selectedJob} onClick={() => dispatch(previousSelectedJob())} variant='outlined'>Previous Lesson</Button>
            <Button disabled={!selectedJob} onClick={ () => dispatch(nextSelectedJob())} variant='outlined'>Next Lesson</Button>
        </div>
    </div>
</Collapse>

      <Dialog
        open={isIDialogOpen}
        onClose={() => setIsIDialogOpen(false)}
        onBlur={() => setIsIDialogOpen(false)}
        aria-labelledby="iterations-modal-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <IterationsModal iterations={iterations} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Dashboard;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supabase from '../../services/supabase';

const initialState = {
    aIQuestions: null,
    status: 'idle',
    error: null,
    loading: false,
};

export const fetchAIQuestions = createAsyncThunk('aIQuestions/fetchAIQuestions', async () => {
    const { data, error } = await supabase.from('aIQuestions').select('*').single();
    if (error) {
        throw error;
    }
    return data;
});

const aIQuestionsSlice = createSlice({
    name: 'aIQuestions',
    initialState,
    reducers: {
        setAIQuestions: (state, action) => {
            state.aIQuestions = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAIQuestions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAIQuestions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.aIQuestions = action.payload;
            })
            .addCase(fetchAIQuestions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    }
});

export const { setAIQuestions } = aIQuestionsSlice.actions;

export default aIQuestionsSlice.reducer;
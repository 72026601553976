import React from 'react';
import { Container, Paper } from '@mui/material';

function AuthLayout(props) {
    const styles = {
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        },
        paper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '16px', // Adjust as needed
            width: '100%',
            maxWidth: '400px',
            backgroundColor: '#fff', // Adjust as needed
            borderRadius: '4px', // Adjust as needed
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)', // Adjust as needed
        },
    };

    return (
        <div style={styles.root}>
            <Container>
                <h1>Auth Layout</h1>
                <Paper style={styles.paper}>
                    {props.children}
                </Paper>
            </Container>
        </div>
    );
}

export default AuthLayout;

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { navigationData } from '../../navigation/sideBarNavigationData';
import NavigationItem from '../../navigation/NavigationItem';
import { signout } from '../../state/user';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';

function LeftSidebar() {
  const dispatch = useDispatch();

  const styles = {
    drawer: {
      width: '240px',
      flexShrink: 0,
    },
    drawerPaper: {
      width: '240px',
      backgroundColor: '#fff', // Adjust as needed
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)', // Adjust as needed
    },
  };

  const handleSignOut = () => {
    dispatch(signout());
  };

  return (
    <Drawer
      style={styles.drawer}
      variant="permanent"
      PaperProps={{ style: styles.drawerPaper }}
    >
      <List>
        {navigationData.map((item, index) => (
          <NavigationItem key={index} label={item.label} path={item.path} />
        ))}
      </List>
      <Button onClick={() => handleSignOut()}>
        Sign Out
      </Button>
    </Drawer>
  );
}

export default LeftSidebar;

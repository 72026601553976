import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Creatable from 'react-select/creatable';
import CreatableSelect from 'react-select/creatable';
import { uploadFileToSupabase } from '../../../../state/uploadQuestions/dashBoardSlice';
// import { submitFileToSupabase } from 'path-to-your-redux-slice'; // Update the import with the actual path and thunk name

function UploadForm({fileRef, paperName, questionPaper, answerPaper, setValidationMessages, reset}) {
  const dispatch = useDispatch();
    const selectedFileIndex = useSelector((state) => state.UploadQDashboard.selectedFileIndex);
    const [season, setSeason] = useState({value: 'none', label: 'None'});
  const [examBoard, setExamBoard] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    year: 0,
    month: '',
    season: 'none',
    has_answers: false,
    is_scan: false,
    number_of_questions: '',
    type: null
  });

  function extractTitle(paperName) {
    if (!paperName) return ""; // handle null or undefined
    const parts = paperName.split(".");
    return parts[0];
}

  React.useEffect(() => {
    if (paperName) {
      const title = extractTitle(paperName);
      setFormData((prevData) => ({
        ...prevData,
        title: title,
      }));
    }
  }, [paperName]);

  function extractInfoFromFilename(filename, optionsArray, setterFunction) {
    const lowerCaseFilename = filename.toLowerCase();
    optionsArray.forEach((option) => {
        const lowerCaseOption = option.label.toLowerCase();
        const pattern = new RegExp(lowerCaseOption, 'g');

      // clear the validation message
      setValidationMessages([])
        if (lowerCaseFilename.match(pattern)) {
            // Match found: Do something with option.value or option.label
            // This could involve setting a state variable, logging output, etc.
            setterFunction(option)
        }
    });
}

function extractYearFromFilename(filename) {
  const match = filename.match(/\b(20\d{2})\b/);
  if (match) {
      const year = parseInt(match[0]);
      setFormData((prevData) => ({
        ...prevData,
        year: year,
      }));
  }
}

const setMonth = (selectedOption) => {
  setFormData((prevData) => ({
    ...prevData,
    month: selectedOption.value,
  }));
};

React.useEffect(() => {
  if (paperName) {
      const title = extractTitle(paperName);

      extractInfoFromFilename(title, examBoardOptions, setExamBoard);
      extractInfoFromFilename(title, monthOptions, setMonth);
      extractInfoFromFilename(title, seasonOptions, setSeason);
      extractInfoFromFilename(title, [{label: 'answer', value: 'answer'}], warnUpload)
      
      // For year, you might want a separate function, given it's numeric.
      extractYearFromFilename(title);
  }
}, [paperName]);

React.useEffect(() => {
  if (answerPaper) {
    const title = extractTitle(questionPaper.name);
    extractInfoFromFilename(title, [{label: 'question', value: 'question'}], warnUpload)
  }
}, [questionPaper]);

const warnUpload = (selectedOption) => {
  setValidationMessages((prevMessages) => ([
    ...prevMessages,
    `Warning: you just uploaded a paper with ${selectedOption.label} in title are you sure you are uploading the correct file?`
  ]));
}


  

  const seasonOptions = [
    { value: 'none', label: 'None'},
    { value: 'spring', label: 'Spring' },
    { value: 'summer', label: 'Summer' },
    { value: 'autumn', label: 'Autumn' },
    { value: 'winter', label: 'Winter' },
  ];

const examBoardOptions = [
    { value: 'GreenLight', label: 'GreenLight' },
    { value: 'Boots', label: 'Boots' },
    { value: 'Bradford', label: 'Bradford' },
    { value: 'Co-op', label: 'Co-op' },
    { value: 'GPhC', label: 'GPhC' },
    { value: 'Keele', label: 'Keele' },
    { value: 'Sunderland', label: 'Sunderland' },
    { value: 'UAE', label: 'UAE' },
    { value: 'RPS', label: 'RPS' },
    { value: 'Propharmace', label: 'Propharmace' },
    { value: 'Other', label: 'Other' },
    {value: 'Allied', label: 'Allied'}
];

const monthOptions = [
  { value: 'January', label: 'January' },
  { value: 'February', label: 'February' },
  { value: 'March', label: 'March' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'June', label: 'June' },
  { value: 'July', label: 'July' },
  { value: 'August', label: 'August' },
  { value: 'September', label: 'September' },
  { value: 'October', label: 'October' },
  { value: 'November', label: 'November' },
  { value: 'December', label: 'December' },
];

  const handleSeasonChange = (selectedOption) => {
    setSeason(selectedOption);
  };

  const handleExamBoardChange = (selectedOption) => {
    setExamBoard(selectedOption);
  };

const currentYear = new Date().getFullYear(); // gets the current year
const startYear = 2000; // or whichever year you want to start from

const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const validateInputs = () => {
    const messages = [];
  
    if (!questionPaper) {
      messages.push('Please upload a question paper.');
    }

    if (!answerPaper && !formData.has_answers) {
      messages.push('Please upload an answer paper.');
    }

    if (!formData.title) {
      messages.push('Please enter a title.');
    }

    if (!formData.year) {
      messages.push('Please enter a year.');
    }

    if (!formData.month) {
      messages.push('Please enter a month.');
    }

    if (!formData.season) {
      messages.push('Please enter a season.');
    }

    if (!formData.number_of_questions) {
      messages.push('Please enter the number of questions.');
    }
    return messages;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const messages = validateInputs();
  
    if (messages.length > 0) {
      // Show validation messages
      setValidationMessages(messages);
      return;  // Stop the function if there are validation messages
    } else {
      setValidationMessages([]);  // Clear any existing messages
    }
  
    // enrich formData with the fileRef
    const fullForrmData = {
      ...formData,
      examBoard: examBoard,
      fileName: questionPaper.name,
    };

    const payload = {
      fullForrmData,
      questionPaper,
      answerPaper,
    };
     
    await dispatch(uploadFileToSupabase(payload));
    reset();
  };
  

  return (
    <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
          Title
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="title"
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
        />
      </div>
      {/* Repeat similar structure for other inputs, remember to use checkbox for boolean values */}
      {/* Year */}
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="year">
        Year
      </label>
      <select
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="year"
        name="year"
        value={formData.year}
        onChange={handleChange}
      >
        {years.map(year => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>
    </div>

    {/* Month */}
   {/* Month */}
   <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="month">
        Month
      </label>
      <select
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="month"
        name="month"
        value={formData.month}
        onChange={handleChange}
      >
        {monthOptions.map(month => (
          <option key={month.value} value={month.value}>{month.label}</option>
        ))}
      </select>
    </div>

    {/* Season */}
    <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="season">
          Season
        </label>
        <CreatableSelect 
          isClearable
          onChange={handleSeasonChange}
          options={seasonOptions}
          value={season}
        />
      </div>

    {/* Exam Board */}
    <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="examBoard">
          Exam Board
        </label>
        <CreatableSelect 
          isClearable
          onChange={handleExamBoardChange}
          options={examBoardOptions} // Initially no options, but users can create
          value={examBoard}
        />
      </div>
    

    {/* Has Answers Checkbox */}
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="has_answers">
        Has Answers
      </label>
      <input
        id="has_answers"
        type="checkbox"
        name="has_answers"
        checked={formData.has_answers}
        onChange={handleCheckboxChange}
      />
    </div>

    {/* Is Scan Checkbox */}
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="is_scan">
        Is Scan
      </label>
      <input
        id="is_scan"
        type="checkbox"
        name="is_scan"
        checked={formData.is_scan}
        onChange={handleCheckboxChange}
      />
    </div>

    {/* Number of Questions */}
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="number_of_questions">
        Number of Questions
      </label>
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="number_of_questions"
        type="number"
        name="number_of_questions"
        value={formData.number_of_questions}
        onChange={handleChange}
      />

<div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="month">
        Type
      </label>
      <select
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="type"
        name="type"
        value={formData.type}
        onChange={handleChange}
      >
        <option key={1} value={'Calculations'}>{'Calculations'}</option>
        <option key={1} value={'Clinical'}>{'Clinical'}</option>
      </select>
    </div>


    </div>
      <div className="flex items-center justify-between">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  );
}

export default UploadForm;

import React, { useEffect } from 'react';
import { TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Typography } from '@mui/material';
import { useFormik } from 'formik';
import ReactQuill from 'react-quill';

function QuestionForm({ selectedQuestion }) {
  console.log("selectedQuestion", selectedQuestion)
  const formik = useFormik({
    initialValues: {
      stem: '',
      main_question: '',
      options: {A: '', B: '', C: '', D: '', E: '', F: '', G: '', H: ''},
      hint: '',
      explanation: '',
      answer: '',
      tags: [],
      subcategory_id: null,
      additional_categories: [],
      type: '', // MCQ/EMCQ/Calculation
      difficulty: null,
      rating: null,
      category_id: 1,
      raw_paper: null,
      unique_key: null,
      resources: '',
      units: '',
    },
    onSubmit: values => {
      // handle form submission here
      console.log(values);
    },
  });

  // useEffect(() => {
  //   if (apiResponse) {
  //     formik.setFieldValue('answer', apiResponse.answer || '');
  //   }
  // }, [apiResponse, formik]);

  console.log("FORMIK VALUES", formik.values, {selectedQuestion})
  useEffect(() => {
    if (selectedQuestion?.stem || selectedQuestion?.main_question) {
      formik.setValues(selectedQuestion);
    } else {
      formik.resetForm();
    }
  }
  , [selectedQuestion]);

  return (
    <div className="p-4">
      <Typography variant="h5" gutterBottom>
        Question Form
      </Typography>
      <form onSubmit={formik.handleSubmit}>
     
          <label htmlFor="stem" className="text-gray-700 font-medium pr-2">
            Stem
          </label>

              <ReactQuill
                value={formik.values.stem}
                onChange={(content) => {
                  const isEmptyVisualContent = ["<p><br></p>", "<div><br></div>"].includes(content);
                  formik.setFieldValue('stem', isEmptyVisualContent ? "" : content);
                }}
              />
            
        <TextField
          label="Main Question"
          multiline
          fullWidth
          margin="normal"
          value={formik.values.main_question}
          onChange={formik.handleChange}
          variant="outlined"
        />

{(formik.values.type === 'MCQ' || formik.values.type === 'EMCQ') && (
<>
    {['A', 'B', 'C', 'D', 'E', ...(formik.values.type === 'EMCQ' ? [ 'F', 'G', 'H'] : [])].map(
      (option) => (
        <div key={option} className="flex flex-col mb-4">
          <label htmlFor={`options.${option}`} className="text-gray-700 font-medium">
            Option {option}
          </label>
          <textarea
            type="text"
            id={`options.${option}`}
            name={`options.${option}`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.options[option] || ''}
            className="border border-gray-400 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:border-blue-500 h-16"
          />
         {formik.touched.options?.[option] && formik.errors.options?.[option] && (
  <div className="text-red-500">{formik.errors.options[option]}</div>
)}

        </div>
      )
    )}
  </>
  )}

<TextField
  label="Answer"
  fullWidth
  margin="normal"
  value={formik.values.answer}
  onChange={formik.handleChange}
  variant="outlined"
/>

{/* <TextField
  label="Hint"
  fullWidth
  margin="normal"
  value={formik.values.hint}
  onChange={formik.handleChange}
  variant="outlined"
/> */}

<TextField
  label="Explanation"
  fullWidth
  margin="normal"
  value={formik.values.explanation}
  onChange={formik.handleChange}
  variant="outlined"
/>



        {/* Add more TextField components for the other keys here... */}

        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default QuestionForm;
import React, { useState, useRef } from 'react';
import { Button, Select, MenuItem, Menu, IconButton } from '@mui/material';
import {  Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { PDFDocument } from 'pdf-lib';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { saveOcrText } from '../../../../state/services/Google';
import { set } from 'react-hook-form';
import { Flag } from '@material-ui/icons';
import { flagCurrentQuetion } from '../../../../state/processQuestions';



const documentAiProcessorUrl = process.env.REACT_APP_CLOUD_FUNCTION_URL
function PaperPreview() {
  const dispatch = useDispatch();
  const [viewMode, setViewMode] = useState('pdf'); // 'pdf' or 'ocr'
  const [isViewingAnswers, setIsViewingAnswers] = useState(false);
  const [ocrText, setOcrText] = useState(''); // TODO: Replace with state or Redux
  const selectedQuestion = useSelector((state) => state.processQuestions.selectedQuestion);
  const [loadedDoc, setLoadedDoc] = useState(null);
  const paper = useSelector((state) => state.processQuestions.selectedPaper);
  const questionNumber = selectedQuestion?.qnumber;
  const totalNumberOfQuestions = paper?.number_of_questions;
  const pdfViewerRef = React.useRef(null);
  const [extractingAndSaving, setExtractingAndSaving] = useState(false);
  const [paperUrl, setPaperUrl] = useState(null); // TODO: Replace with state or Redux
  const [dropDown, setDropDown] = useState('paper')

  console.log("PAPER TRIAL", paper)
  const pageNavigationPluginInstance = pageNavigationPlugin();

  // console.log("SELECTED QUESTION", questionNumber, totalNumberOfQuestions)
  
  
  const splitPdf = async (pdfBuffer, maxPagesPerChunk = 15) => {
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const totalPages = pdfDoc.getPageCount();
    let fullExtractedText = ""; // to aggregate text from all chunks
    let chunksArray = []; // to store all pdf chunks
    
    for (let i = 0; i < totalPages; i += maxPagesPerChunk) {
      const pdfChunk = await PDFDocument.create();
      for (let j = i; j < i + maxPagesPerChunk && j < totalPages; j++) {
        const [page] = await pdfChunk.copyPages(pdfDoc, [j]);
        pdfChunk.addPage(page);
      }
      const pdfChunkBuffer = await pdfChunk.save();
      chunksArray.push(pdfChunkBuffer); // Store the chunk in the array
    }
    return chunksArray; // Return the array of chunks
  };

  
  const scrollPercentage = (questionNumber / totalNumberOfQuestions) * 100;
  const scrollToQuestion = () => {
    
    if (pdfViewerRef.current) {
  
      const totalHeight = pdfViewerRef.current.scrollHeight;
      const scrollTo = (scrollPercentage / 100) * totalHeight;
      pdfViewerRef.current.scrollTop = scrollTo;

    }
  };

  React.useEffect(() => {
    scrollToQuestion();
  }, [selectedQuestion]);
  
  


  const toggleViewMode = () => {
    setViewMode((prevMode) => (prevMode === 'pdf' ? 'ocr' : 'pdf'));
  };

  const toggleAnswersView = () => {
    setIsViewingAnswers((prev) => !prev); // Toggle between true and false
  };

  const CLOUD_FUNCTION_URL = documentAiProcessorUrl; // Replace with your Cloud Function URL

  const handleOcrClick = async () => {
    // Fetch the PDF as an ArrayBuffer
    setExtractingAndSaving(true)
    try {
      const response = await fetch(paper?.file_url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const pdfBuffer = await response.arrayBuffer();
      
      // Split the PDF into chunks and perform OCR
      const pdfChunks = await splitPdf(pdfBuffer);
      
      let fullExtractedText = '';
      for (const chunk of pdfChunks) {
        const ocrResponse = await fetch(CLOUD_FUNCTION_URL, {
          method: 'POST',
          body: chunk
        });
        if (ocrResponse.ok) {
          console.log('OCR successful!', ocrResponse)
          const ocrData = await ocrResponse.text();
          fullExtractedText += ocrData;
        }
      }
      // TODO: Store `fullExtractedText` in state or Redux, or update the UI accordingly
      console.log('Full extracted text:', fullExtractedText);
      setViewMode('ocr')
      setOcrText(fullExtractedText)
      dispatch(saveOcrText({ocrText: fullExtractedText, paperId: paper?.id}))
      setExtractingAndSaving(false)

    } catch (error) {
      console.error("Error fetching PDF:", error);
      setExtractingAndSaving(false)
    }
  };
  
 
  const ocr = paper?.ocr;
  const answers = paper?.past_paper_answers?.file_url;
  const resources = paper?.past_paper_resources[0]?.source_url;

  // console.log({paper, ocr})

  // Determine the source URL based on state
  const sourceUrl = isViewingAnswers ? answers : paper?.file_url;

  React.useEffect(() => {
    if(paper){
      setPaperUrl(paper?.file_url)
    }
    if(paper && paper?.ocr){
      setOcrText(paper?.ocr)
    }
  }
  , [paper])

  const handleDocumentLoad = ({ doc }) => {
    
    setLoadedDoc(doc); // Save the loaded document
  }

  React.useEffect(() => {
    if (selectedQuestion && paper) {
      const totalPages = paper?.number_of_questions; // Assuming 'number_of_questions' represents total pages in the PDF.
      const pageToNavigate = Math.ceil((questionNumber / totalNumberOfQuestions) * totalPages);

      // Use the jumpToPage method from the plugin
      pageNavigationPluginInstance.jumpToPage(pageToNavigate - 1); // Page numbers usually start from 0.
    }
}, [selectedQuestion]);



  
  return (
    <div className="flex flex-col h-full">
      <div className="p-4 bg-white-500 bg-opacity-10 text-white flex justify-between items-center">
        <Button 
          variant="contained" 
          onClick={toggleViewMode}
          disabled={!paper?.ocr}
        >
          View: {viewMode.toUpperCase() === 'PDF' ? 'OCR' : 'PDF'}
        </Button>

        <Button 
        variant="contained" 
        onClick={handleOcrClick}
        disabled={paper?.ocr}
      >
     
        {!extractingAndSaving && <span>    Extract Text (OCR)</span>}
        {extractingAndSaving && <span className="animate-pulse">...Extracting & Saving</span>}
      </Button>
      <IconButton disabled={!paper || !selectedQuestion} onClick={() =>{
        console.log({selectedQuestion})
        dispatch(flagCurrentQuetion({questionId: selectedQuestion?.id, paperId: paper?.id ,flag: !selectedQuestion?.flagged}))
      }}>
        <Flag style={{color:selectedQuestion?.flagged ? 'red' : 'GrayText'}}/>
      </IconButton>

      <Select
    value={dropDown}
    onChange={(event) => {
      const value = event.target.value;
      if (value === 'paper') {
        setPaperUrl(paper?.file_url)
        setDropDown('paper')
      } else if (value === 'answers') {
        setPaperUrl(paper?.past_paper_answers?.file_url)
        setDropDown('answers')
      } else if (value === 'resources') {
        setPaperUrl(paper?.past_paper_resources[0]?.source_url)
        setDropDown('resources')
      }
    }}
    disabled={!answers}
  >
    <MenuItem value="paper">Paper</MenuItem>
    <MenuItem value="answers">Answers</MenuItem>
    <MenuItem value="resources" disabled={!paper?.past_paper_resources?.length}>Resources</MenuItem>
  </Select>
      </div>

      <div className="flex-grow relative h-full">
      {viewMode === 'pdf' ? (
                    paperUrl ? (
                      <div
                      style={{
                          border: '1px solid rgba(0, 0, 0, 0.3)',
                          height: '100vh',
                      }}
                    
                  >
                      <Viewer   
                       fileUrl={paperUrl}
                       onDocumentLoad={handleDocumentLoad}
                       plugins={[pageNavigationPluginInstance]} 
                        />
                  </div>
                    ) : (
                        <p>Select a Paper</p>
                    )
                ) : (
                    <textarea
                    ref={pdfViewerRef}
                        value={ocrText}
                        onChange={(prev) => setOcrText(prev.target.value)}
                        className="p-4 h-full overflow-auto w-full"
                    />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={ async () => {
                    setExtractingAndSaving(true)
                    await dispatch(saveOcrText({ocrText, paperId: paper?.id}))
                    setExtractingAndSaving(false)
                  }}
                  disabled={extractingAndSaving}
                >
                  {
                    extractingAndSaving ? <span className='animate-pulse' >Saving...</span> : 'Save'
                  }
                </Button>

      </div>
    </div>
  );
}
  
export default PaperPreview;

import React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import supabase from '../../../services/supabase';
import { uploadVQuestions, toggleDialog, fixJson } from '../../../state/imagify/imagifySlice';
import { useDispatch, useSelector } from 'react-redux';



function Dashboard({ 
  toggleDrawer, selectedPaper, 
  convertPdfToImages, selectedQuestion, 
  questions, setQuestion,
  paperImages, selectedPaperImage,
  selectedImages, selectedQuestions,
  addSelectedImage, addSelectedQuestion,
  justaddImage, justaddQuestion, setImage, messages,
}) {
    const selectedPaperDefined = selectedPaper ? true : false;
    const PdfFile = selectedPaper ? selectedPaper.file_url : null;
    const file_name = selectedPaper ? selectedPaper.file_name : null;
    const [isSubmitActive, setIsSubmitActive] = React.useState(false);
    const loading = useSelector((state) => state.imagifySlice.loading);
    const systemMessage = useSelector((state) => state.imagifySlice.systemMessage);
    const vision_id = 25;
    const dispatch = useDispatch();

    const showfix = systemMessage == "Error parsing JSON, please check your output and try again" ? true : false;

    React.useEffect(() => {
      const isAnyQuestionPink = questions.some(question => question.author_id == vision_id && question.pendingSubmit);
      setIsSubmitActive(isAnyQuestionPink);
    }, [questions]);
    

const handleImageClick = (image) => {
  setImage(image);
  justaddImage(image);
  console.log("image", image)
}  

React.useEffect(() => {
  if (selectedQuestion !== null) {
    const updatedIndex = questions.findIndex(question => question?.qnumber === selectedQuestion?.qnumber);
    const updatedQuestion = questions[updatedIndex];
    setQuestion(updatedQuestion);
    justaddQuestion(updatedQuestion);
  }
}, [questions]);


const handleSubmit = async () => {
  // Filter out only the pink questions (both vision and pendingSubmit are true)
  const pinkQuestions = questions.filter(question => question.author_id == vision_id && question.pendingSubmit);

  // Update these pink questions: turn pendingSubmit to false
  const updatedPinkQuestions = pinkQuestions.map(question => {
    const updatedQuestion = {
      ...question,
      unique_key: `${selectedPaper.id}_${question.qnumber}`,
      raw_paper: selectedPaper.id,
      category_id: 1,
    };
    delete updatedQuestion.pendingSubmit; // Removes the 'pendingSubmit' field
    return updatedQuestion;
  });
 
  // Upload the pink questions to the database
  console.log("pinkQuestions", updatedPinkQuestions)
  dispatch(uploadVQuestions(updatedPinkQuestions));
};




const handleQuestionClick = (index) => {
   // Check if there's already a question with a matching qnumber.
  
   const existingQuestion = questions.find((q) => q?.qnumber === index + 1);
   console.log("HANDLe quesiton CLick", index, existingQuestion)
   if (existingQuestion) {
    // If the question exists, select it.
    setQuestion(existingQuestion);
    justaddQuestion(existingQuestion);
  } else {
    // If it doesn't, create a new question with the clicked index + 1 as its qnumber.
    const newQuestion = {
      qnumber: index + 1,
      // ... other properties ...
    };
  setQuestion(newQuestion);
 justaddQuestion(newQuestion);
  }

  console.log(
  "selectedQuestion", selectedQuestion,  "questions", questions
  )
}

  return (
    <div >
      {/* IconButton to toggle the drawer */}
      <div className="flex items-center justify-between p-4 bg-white border-b border-gray-300">

      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer}
      >
        <MenuIcon />
      </IconButton>

      <h1 className="text-lg font-semibold">Your Dashboard Title</h1>

      {/* Convert PDF to Images button */}
      
      <Button
        variant="contained"
        color="primary"
        disabled={!selectedQuestions.length || !selectedImages.length || loading}
        onClick={() => convertPdfToImages()}
      >
       {loading ? <span className="animate-pulse">processing</span> : 'VisionAi Fill'}
      </Button>

      {/* Submit to Toggle Dialog */}
      <Button
        variant="outlined"
        color="primary"
        onClick={() => dispatch(toggleDialog())}
        disabled={!messages.length || loading}
      >
        Dialogue
      </Button>

      <Button
        variant="outlined"
        color="primary"
        onClick={handleSubmit}
        disabled={!isSubmitActive || loading}
      >
        {loading ? <span className="animate-pulse">processing</span> : 'Submit DB'}
      </Button>

     {
        true ? <Button
        variant="outlined"
        color="primary"
        onClick={() => dispatch(fixJson())}
        disabled={loading} //!showFix
      >
        Fix JSON
      </Button> : null
     }
      
      </div>
      {
        systemMessage?.length && (
          <div className="p-4 bg-white border-b border-gray-300">
            <div className="text-sm text-gray-500">
              {systemMessage}
            </div>
          </div>
        )
      }
      <Typography variant="h6" component="div">
     Group Select
      </Typography>
      <div className="flex items-center space-x-4">
        <Typography variant="h6" component="div">
          Selected Questions
        </Typography>
        <div className="flex space-x-4">
          {selectedQuestions.length && selectedQuestions.map((question) => (
            <div
              key={question}
              onClick={() => addSelectedQuestion(question)}
              className={`w-8 h-8 border-2 flex items-center justify-center cursor-pointer text-white bg-gray-500`}
            >
              {question.qnumber}
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <Typography variant="h6" component="div">
          Selected Images
        </Typography>
        <div className="flex space-x-4">
          {selectedImages.length && selectedImages.map((image) => (
            <div
              key={image}
              onClick={() => addSelectedImage(image)}
              className={`w-8 h-8 border-2 flex items-center justify-center cursor-pointer text-white bg-gray-500`}
            >
              {image.page_number}
            </div>
          ))}
        </div>
      </div>
      
      <div className="flex space-x-4">

        <div>
          <Typography variant="h6" component="div">
            Questions
          </Typography>
          <div className="flex flex-wrap space-x-2 space-y-2 p-4 overflow-auto max-h-64">
          {selectedPaper && (
  <div className="flex flex-wrap space-x-2 space-y-2 p-4">
    {Array.from({ length: selectedPaper.number_of_questions }, (_, index) => {
      const questionNumber = index + 1;
      const existingQuestion = questions.find((q) => q.qnumber === questionNumber);

      const isSelected = questionNumber === selectedQuestion?.qnumber;
      const isProcessed = existingQuestion != null;
      const isPink = existingQuestion?.author_id == vision_id && existingQuestion?.pendingSubmit; // Check for both flags

      let backgroundColorClass;
      if (isSelected) {
        backgroundColorClass = 'bg-blue-600'; // Selected question
      } else if (isPink) {
        backgroundColorClass = 'bg-pink-500'; // Question with both flags
      } else if (isProcessed) {
        backgroundColorClass = 'bg-green-500'; // Processed but not selected
      } else {
        backgroundColorClass = 'bg-gray-500'; // Unprocessed question
      }

      return (
        <div
          key={index}
          onClick={() => handleQuestionClick(index)}
          className={`w-8 h-8 border-2 flex items-center justify-center cursor-pointer text-white ${backgroundColorClass}`}
        >
          {questionNumber}
        </div>
      );
    })}
  </div>
)}
          </div>
        </div>

        <div>
          <Typography variant="h6" component="div">
            Paper Images
          </Typography>
          <div className="flex flex-wrap space-x-2 space-y-2 p-4 overflow-auto max-h-64">
            {paperImages && (
              paperImages.map( (image, index) => {
                const isSelected = selectedPaperImage?.id === image?.id;
                return (
                  <div
                    key={index}
                    onClick={() => handleImageClick(image)}
                    className={`w-8 h-8 border-2 flex items-center justify-center cursor-pointer text-white ${
                      isSelected
                        ? 'bg-blue-600'
                        // : existingQuestion
                        // ? 'bg-green-500'
                        : 'bg-gray-500'
                    }`}
                  >
                    {index + 1}
                  </div>
                );
              })
            )}
          </div>
        </div>

      </div>
    </div>
  );
}

export default Dashboard;
